import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import swal from "sweetalert";
import AuthApi from "../../Services/Authapi";
import TextField from '@material-ui/core/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import draftToHtml from 'draftjs-to-html';
import AuthCheckComponent from '../../Layouts/Token';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const iniLatter = {
    title: '',
    texteditor: '',
    templates: [{ label: '', type: '' }]
}

const Latter = (props) => {
    const classes = props;
    const [doc, setDoc] = useState(iniLatter);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [templateData, setTemplateData] = useState([]); 

    useEffect(() => {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }
    }, [props]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDoc({ ...doc, [name]: value });
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setDoc({ ...doc, texteditor: data });
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[a-zA-Z]/;

        if (!values.title) {
            errors.title = "Cannot be blank";
        } else if (!regex.test(values.title)) {
            errors.title = "Invalid title format";
        }

        values.templates.forEach((template, index) => {
            if (!template.label) {
                errors[`templateLabel${index}`] = "Template label cannot be blank";
            }
            if (!template.type) {
                errors[`templateType${index}`] = "Template type cannot be blank";
            }
        });

        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            createLeaves();
        }
    }, [formErrors, isSubmitting]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(doc));
        setIsSubmitting(true);
        // console.log(doc);
    };

    const createLeaves = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;"
                }
            }
        });
        try {
            let create = await AuthApi.Templatepostdata(doc);
            swal.close();
            if (create && create.status === true) {
                props.history.push("/latter");
            } else {
                swal({
                    title: "OOPS!",
                    icon: "fail",
                    message: "Something went wrong, Please try later!",
                });
            }
        } catch (error) {
            console.error("Error updating role:", error);
        }
    };

    const addTemplateField = () => {
        setDoc({ ...doc, templates: [...doc.templates, { label: '', type: '' }] });
    };

    const removeTemplateField = (index) => {
        const newTemplates = doc.templates.filter((_, i) => i !== index);
        setDoc({ ...doc, templates: newTemplates });
    };

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb {...props} primaryPageName="User Template" primaryPageLink="/latter" isSecondaryPage={false} secondaryPageName="" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2" style={{ paddingTop: 0, paddingLeft: 0, paddingBottom: 0 }}>Generate Template</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit}>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        label="Title"
                                                        name="title"
                                                        variant="outlined"
                                                        error={formErrors.title && true}
                                                        value={doc.title}
                                                        onChange={handleChange}
                                                        className={formErrors.title && "input-error"}
                                                    />
                                                    {formErrors.title && (
                                                        <span className="error">{formErrors.title}</span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={doc.texteditor}
                                                        onChange={handleEditorChange}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* <Grid container className={classes.root} spacing={5}> */}
                                            {doc.templates.map((template, index) => (
                                                <Grid container spacing={5} key={index}>
                                                    <Grid item xs={5}>
                                                        <TextField
                                                            fullWidth
                                                            id={`outlined-template-label-${index}`}
                                                            label="Template Label"
                                                            name={`templateLabel${index}`}
                                                            variant="outlined"
                                                            error={formErrors[`templateLabel${index}`] && true}
                                                            value={template.label}
                                                            onChange={(e) => {
                                                                const newTemplates = [...doc.templates];
                                                                newTemplates[index].label = e.target.value;
                                                                setDoc({ ...doc, templates: newTemplates });
                                                            }}
                                                            className={formErrors[`templateLabel${index}`] && "input-error"}
                                                        />
                                                        {formErrors[`templateLabel${index}`] && (
                                                            <span className="error">{formErrors[`templateLabel${index}`]}</span>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <FormControl fullWidth error={formErrors[`templateType${index}`] && true}>
                                                            <InputLabel id={`template-type-label-${index}`}>Template Type</InputLabel>
                                                            <Select
                                                                labelId={`template-type-label-${index}`}
                                                                id={`outlined-template-type-${index}`}
                                                                name={`templateType${index}`}
                                                                label="Template Type"
                                                                value={template.type}
                                                                onChange={(e) => {
                                                                    const newTemplates = [...doc.templates];
                                                                    newTemplates[index].type = e.target.value;
                                                                    setDoc({ ...doc, templates: newTemplates });
                                                                }}
                                                                className={formErrors[`templateType${index}`] && "input-error"}
                                                            >
                                                                <MenuItem value="text">Text</MenuItem>
                                                                <MenuItem value="date">Date</MenuItem>
                                                                <MenuItem value="email">Email</MenuItem>
                                                                <MenuItem value="month">Month</MenuItem>
                                                                <MenuItem value="number">Number</MenuItem>
                                                                <MenuItem value="textarea">Textarea </MenuItem>
                                                                <MenuItem value="password">Password</MenuItem>
                                                                <MenuItem value="time">Time</MenuItem>
                                                                <MenuItem value="color">Color</MenuItem>
                                                                <MenuItem value="hidden">Hidden</MenuItem>
                                                                <MenuItem value="submit">Submit</MenuItem>
                                                                <MenuItem value="radio">Radio</MenuItem>
                                                                <MenuItem value="checkbox">Checkbox</MenuItem>
                                                                <MenuItem value="file">Image</MenuItem>
                                                            </Select>
                                                            {formErrors[`templateType${index}`] && (
                                                                <span className="error">{formErrors[`templateType${index}`]}</span>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    {template.type === 'radio' && (
                                                        <Grid item xs={10}>
                                                            {template.options && template.options.map((option, optIndex) => (
                                                                <Grid container spacing={2} key={optIndex}>
                                                                    <Grid item xs={8}>
                                                                        <TextField
                                                                            fullWidth
                                                                            id={`outlined-radio-option-${index}-${optIndex}`}
                                                                            label={`Option ${optIndex + 1}`}
                                                                            value={option}
                                                                            onChange={(e) => {
                                                                                const newTemplates = [...doc.templates];
                                                                                newTemplates[index].options[optIndex] = e.target.value;
                                                                                setDoc({ ...doc, templates: newTemplates });
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                const newTemplates = [...doc.templates];
                                                                                newTemplates[index].options.splice(optIndex, 1);
                                                                                setDoc({ ...doc, templates: newTemplates });
                                                                            }}
                                                                            variant="contained"
                                                                            style={{
                                                                                color: "#fff",
                                                                                backgroundColor: "#d12c2c",
                                                                                marginTop: "10px"
                                                                            }}>
                                                                            Remove
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                            <Button
                                                                onClick={() => {
                                                                    const newTemplates = [...doc.templates];
                                                                    if (!newTemplates[index].options) {
                                                                        newTemplates[index].options = [];
                                                                    }
                                                                    newTemplates[index].options.push('');
                                                                    setDoc({ ...doc, templates: newTemplates });
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ marginTop: "10px" }}>
                                                                Add Option
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                    {template.type === 'checkbox' && (
                                                        <Grid item xs={10}>
                                                            {template.options && template.options.map((option, optIndex) => (
                                                                <Grid container spacing={2} key={optIndex}>
                                                                    <Grid item xs={8}>
                                                                        <TextField
                                                                            fullWidth
                                                                            id={`outlined-checkbox-option-${index}-${optIndex}`}
                                                                            label={`Option ${optIndex + 1}`}
                                                                            value={option}
                                                                            onChange={(e) => {
                                                                                const newTemplates = [...doc.templates];
                                                                                newTemplates[index].options[optIndex] = e.target.value;
                                                                                setDoc({ ...doc, templates: newTemplates });
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                const newTemplates = [...doc.templates];
                                                                                newTemplates[index].options.splice(optIndex, 1);
                                                                                setDoc({ ...doc, templates: newTemplates });
                                                                            }}
                                                                            variant="contained"
                                                                            style={{
                                                                                color: "#fff",
                                                                                backgroundColor: "#d12c2c",
                                                                                marginTop: "10px"
                                                                            }}>
                                                                            Remove
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                            <Button
                                                                onClick={() => {
                                                                    const newTemplates = [...doc.templates];
                                                                    if (!newTemplates[index].options) {
                                                                        newTemplates[index].options = [];
                                                                    }
                                                                    newTemplates[index].options.push('');
                                                                    setDoc({ ...doc, templates: newTemplates });
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ marginTop: "10px" }}>
                                                                Add Option
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={2}>
                                                        <Button
                                                            onClick={() => removeTemplateField(index)}
                                                            variant="contained"
                                                            style={{
                                                                color: "#fff",
                                                                backgroundColor: "#d12c2c",
                                                                marginTop: "10px"
                                                            }}>
                                                            Delete
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                                <Button onClick={addTemplateField} variant="contained" color="primary">
                                                    Add Input Field
                                                </Button>
                                            </Grid>
                                            {/* </Grid> */}
                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4}>
                                                    <Button type="submit" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} variant="contained" color="primary">Save</Button>
                                                    <Button type="button" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} onClick={(e) => { e.preventDefault(); props.history.push('/latter') }}>Cancel</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                } />

            <Footer />
        </div>
    )
}

export default Latter;