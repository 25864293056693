import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useHistory } from 'react-router-dom';
// import OfferLatter from './OfferLatter';
// import Salaryslip from './Salaryslip';
// import Relievingletter from './Relievingletter';
import AuthCheckComponent from '../../Layouts/Token';
import AuthApi from "../../Services/Authapi";
import TableComponent from '../../Layouts/TableComponent';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import swal from "sweetalert";
import Salaryslip from './Salaryslip'; 


const Documentslist = (props) => {
    const history = useHistory();
    const [selectedValue, setSelectedValue] = useState('');
    const [data, setdata] = useState([]);
    const [tabledata, settabledata] = useState([]);
    const [tableTh, setTableTh] = useState([
        { id: 'serial_number', numeric: false, disablePadding: true, label: 'Serial Number', sortable: true },
        { id: 'default', numeric: false, disablePadding: true, label: 'Default', sortable: true }
    ]);
    const pageStatus = {};
    const permissions = (props.authUser && Array.isArray(props.authUser.userAccess)) ? props.authUser.userAccess : [];
    const [selectedTemplateTemplates, setSelectedTemplateTemplates] = useState([]); 
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [user, setUser] = useState(null); 
    const [showSalarySlip, setShowSalarySlip] = useState(false); 
    useEffect(() => {
        Templategetdata();
    }, []);

    const Templategetdata = async () => {
        try {
            swal({
                title: "Loading",
                text: "Please wait...",
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
                width: "10%",
                content: {
                    element: "img",
                    attributes: {
                        src: "https://www.boasnotas.com/img/loading2.gif",
                        style: "width: 50px;"
                    }
                }
            });
            let role = await AuthApi.Templategetdata();
            swal.close();
            if (Array.isArray(role.data)) {
                const filteredData = role.data.filter(item => item.status === 1); 
                setdata(filteredData);
            } else {
                console.error('Data is not an array:', role.data);
            }
        } catch (error) {
            swal.close();
            console.error('Error fetching data:', error);
        }
    };

    const handleSelectChange = async (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        if (newValue === "Salary Slip") {
            setShowSalarySlip(true); 
        } else {
            setShowSalarySlip(false); 
            if (newValue) {
                const selectedTemplate = data.find(item => item.template_name === newValue);
                if (selectedTemplate) {
                    setSelectedTemplateTemplates(selectedTemplate.templates);
                    const labels = selectedTemplate.templates.map(template => template.label);
                    const newTableTh = [
                        { id: 'serial_number', numeric: false, disablePadding: true, label: 'Serial Number', sortable: true },
                        ...labels.map(label => ({
                            id: label,
                            numeric: false,
                            disablePadding: true,
                            label: label.charAt(0).toUpperCase() + label.slice(1),
                            sortable: true,
                        }))
                    ];
                    setTableTh(newTableTh);
                }
                await getTemplate(newValue);
            }
        }
    };

    // const getTemplate = async (templateValue) => {
    //     try {
    //         swal({
    //             title: "Loading",
    //             text: "Please wait...",
    //             buttons: false,
    //             closeOnClickOutside: false,
    //             closeOnEsc: false,
    //             width: "10%",
    //             content: {
    //                 element: "img",
    //                 attributes: {
    //                     src: "https://www.boasnotas.com/img/loading2.gif",
    //                     style: "width: 50px;"
    //                 }
    //             }
    //         });
    //         let response = await AuthApi.alllettersget(templateValue);
    //         swal.close();
    //         if (response && response.status === true) {
    //             // console.log(response.data);
    //             // const fields = Object.keys(response.data[0]?.data || {});
    //             // const newTableTh = [
    //             //     { id: 'serial_number', numeric: false, disablePadding: true, label: 'Serial Number', sortable: true },
    //             //     ...fields.map(field => ({
    //             //         id: field,
    //             //         numeric: false,
    //             //         disablePadding: true,
    //             //         label: field.charAt(0).toUpperCase() + field.slice(1),
    //             //         sortable: true,
    //             //     }))
    //             // ];
    //             // setTableTh(newTableTh);

    //             settabledata(response.data.map((item, index) => ({
    //                 id: item.id,
    //                 serial_number: index + 1,
    //                 ...item.data,
    //             })));
    //         } else {
    //             console.error("Failed to fetch templates: ", response);
    //         }
    //     } catch (error) {
    //         swal.close();
    //         console.error("Error fetching template data:", error);
    //     }
    // };
    const getTemplate = async (templateValue) => {
        try {
            swal({
                title: "Loading",
                text: "Please wait...",
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
                width: "10%",
                content: {
                    element: "img",
                    attributes: {
                        src: "https://www.boasnotas.com/img/loading2.gif",
                        style: "width: 50px;"
                    }
                }
            });
            let response = await AuthApi.alllettersget(templateValue);
            swal.close();
            if (response && response.status === true) {
                settabledata(response.data.map((item, index) => {
                    const rowData = {
                        id: item.id,
                        serial_number: index + 1,
                        ...item.data,
                    };

                    Object.keys(rowData).forEach(key => {
                        if (typeof rowData[key] === 'string' && rowData[key].match(/\.(jpeg|jpg|gif|png)$/)) {
                            rowData[key] = <img src={rowData[key]} alt={key} style={{ width: '100px', height: '50px' }} />;
                        }
                    });

                    return rowData;
                }));
            } else {
                console.error("Failed to fetch templates: ", response);
            }
        } catch (error) {
            swal.close();
            console.error("Error fetching template data:", error);
        }
    };

    const openModal = async (id) => { 
        try {
            swal({
                title: "Loading",
                text: "Please wait...",
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
                width: "10%",
                content: {
                    element: "img",
                    attributes: {
                        src: "https://www.boasnotas.com/img/loading2.gif",
                        style: "width: 50px;"
                    }
                }
            });
            const userData = await AuthApi.dynamicletterview(id, selectedValue); 
            swal.close();
            if (userData) {
                setUser(userData); 
                setIsModalOpen(true); 
            }
        } catch (error) {
            swal.close();
            console.error("Error opening modal:", error);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false); 
    };

    const downloadofferletter = async (id) => { 
        try {
            swal({
                title: "Loading",
                text: "Please wait...",
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
                width: "10%",
                content: {
                    element: "img",
                    attributes: {
                        src: "https://www.boasnotas.com/img/loading2.gif",
                        style: "width: 50px;"
                    }
                }
            });
            let users = await AuthApi.dynamicletterdownload(id, selectedValue);
            swal.close();
            if (users && users.status === true) {

            }
        } catch (error) {
            swal.close();
            console.error("Error downloading offer letter:", error);
        }
    };

    const removeUser = async (id) => { 
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Cancel", "Yes"],
        }).then(async (confirm) => {
            if (confirm) {
                try {
                    swal({
                        title: "Loading",
                        text: "Please wait...",
                        buttons: false,
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        width: "10%",
                        content: {
                            element: "img",
                            attributes: {
                                src: "https://www.boasnotas.com/img/loading2.gif",
                                style: "width: 50px;"
                            }
                        }
                    });
                    let currentEvent = await AuthApi.dynamicletterdelete(id);
                    swal.close();
                    if (currentEvent && currentEvent.status === true) {
                        await getTemplate(selectedValue); 
                    }
                } catch (error) {
                    swal.close();
                    console.error("Error removing user:", error);
                }
            }
        });
    };



    const navigateToOfferLatterAdd = () => {
        history.push({
            pathname: '/Documents/OfferLatterAdd',
            state: {
                templates: selectedTemplateTemplates,
                templateName: selectedValue
            }
        });
    };


    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="Documents List"
                                    primaryPageLink="/Documentslist"
                                    isSecondaryPage={false}
                                    secondaryPageName=""
                                />
                            </CardContent>
                        </Card>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">All Letter</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    onChange={handleSelectChange}
                                    label="All Letter"
                                >
                                    {data.length > 0 && data.map((item) => (
                                        <MenuItem key={item.id} value={item.template_name}>{item.template_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        {showSalarySlip && <Salaryslip   {...props}/>} 

                        {selectedValue && !showSalarySlip && (
                            <>
                                <TableComponent
                                    {...props}
                                    tableTh={[
                                        ...tableTh,
                                        {
                                            id: "actions",
                                            numeric: false,
                                            disablePadding: true,
                                            label: "Actions",
                                            sortable: false,
                                        },
                                    ]}
                                    tableData={tabledata}
                                    tableCount={tabledata.length}
                                    tablePagestatus={pageStatus}
                                    colNameToShow={tableTh.map(th => th.id)}
                                    removeRow={(id) => removeUser(id)}
                                    showRow={(id) => openModal(id)}
                                    downloadRow={(id) => downloadofferletter(id)}
                                    openPopUp={false}
                                    actionBtns={[
                                        permissions.includes("Offerletter.View") && ('show'),
                                        permissions.includes("Offerletter.Delete") && ('delete'),
                                        permissions.includes("Offerletter.Download") && ('download')
                                    ]}
                                    modelName={selectedValue}
                                    // addRoute={`/Documents/OfferLatterAdd?templates=${encodeURIComponent(JSON.stringify(selectedTemplateTemplates || []))}&template_name=${encodeURIComponent(selectedValue)}`} updateRoute={"/users/edit"}
                                    addRoute={navigateToOfferLatterAdd} 
                                    openPopUpUpdate={false}
                                />
                            </>
                        )}
                        {isModalOpen && ( 
                            <Dialog open={isModalOpen} onClose={closeModal} style={{ marginTop: "35px" }} >
                                <DialogTitle>
                                    {selectedValue}
                                </DialogTitle>
                                <DialogContent>
                                    {user && (
                                        <iframe
                                            title="Offer Letter"
                                            src={user}
                                            width="100%"
                                            height="500px"
                                        />
                                    )}
                                </DialogContent>
                            </Dialog>
                        )}
                    </div>
                }
            />
            <Footer />
        </div>
    );
};

export default Documentslist;
