import React, { Component } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DataGrid } from '@mui/x-data-grid';
import AuthApi from '../../Services/Authapi';
import ls from "local-storage";
import Chart from "chart.js/auto";
import "./Attendance.css";
import AuthCheckComponent from '../../Layouts/Token';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

class Attendance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Attendance: [],
            dailyLogData: [],
            searchQueryMonthly: "",
            searchQueryDaily: "",
            suggestionsMonthly: [],
            suggestionsDaily: [],
            selectedDate: new Date().toISOString().split('T')[0].slice(0, 7),
            selectedDatejh: new Date().toISOString().split('T')[0],
            showMonthlyLog: false,
            pageSizeMonthly: 5, // Separate state for monthly log page size
            pageSizeDaily: 5,   // Separate state for daily log page size
            columns: [
                { field: 'user_name', headerName: 'Employee ID', flex: 1, headerClassName: 'header-red' },
                { field: 'firstname', headerName: 'Name', flex: 1, headerClassName: 'header-red' },
                { field: 'date', headerName: 'Date', flex: 1, headerClassName: 'header-red' },
                { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'header-red' },
                { field: 'first_in_time', headerName: 'First In Time', flex: 1, headerClassName: 'header-red' },
                { field: 'last_out_time', headerName: 'Last Out Time', flex: 1, headerClassName: 'header-red' },
                { field: 'total_duration', headerName: 'Total Duration', flex: 1, headerClassName: 'header-red' },
                { field: 'break_duration', headerName: 'Break Duration', flex: 1, headerClassName: 'header-red' },
                { field: 'breaks', headerName: 'Breaks', flex: 1, headerClassName: 'header-red' },
            ],
            dailyLogColumns: [
                { field: 'serial_no', headerName: 'Serial No', flex: 1, headerClassName: 'header-red' },
                { field: 'user_name', headerName: 'Employee ID', flex: 1, headerClassName: 'header-red' },
                { field: 'firstname', headerName: 'Name', flex: 1, headerClassName: 'header-red' },
                { field: 'date', headerName: 'Date', flex: 1, headerClassName: 'header-red' },
                { field: 'time', headerName: 'Time', flex: 1, headerClassName: 'header-red' },
                { field: 'type', headerName: 'Type', flex: 1, headerClassName: 'header-red' },
                { field: 'duration', headerName: 'Duration', flex: 1, headerClassName: 'header-red' },
            ],
        };
        this.chartRef = React.createRef();
        this.handleChangeMonthly = this.handleChangeMonthly.bind(this);
        this.handleChangeDaily = this.handleChangeDaily.bind(this);
        this.handleSearchMonthly = this.handleSearchMonthly.bind(this);
        this.handleSearchDaily = this.handleSearchDaily.bind(this);
        this.clearSearchMonthly = this.clearSearchMonthly.bind(this);
        this.clearSearchDaily = this.clearSearchDaily.bind(this);
        this.handleSuggestionClickMonthly = this.handleSuggestionClickMonthly.bind(this);
        this.handleSuggestionClickDaily = this.handleSuggestionClickDaily.bind(this);
        this.handlePageSizeChangeMonthly = this.handlePageSizeChangeMonthly.bind(this);
        this.handlePageSizeChangeDaily = this.handlePageSizeChangeDaily.bind(this);
    }

    componentDidMount() {
        // if (!this.props.authToken) {
        //     this.props.history.push("/login");
        //     return;
        // }
        this.fetchData();
        this.fetchDailyLog();
    }

    fetchData = async () => {
        const { selectedDate } = this.state;
        const [year, month] = selectedDate.split('-');

        try {
            const roles = ls.get("roles");
            let response;

            if (roles && (roles.includes("Super Admin") || roles.includes("Admin"))) {
                response = await AuthApi.getallAttendance(month, year);
            } else {
                response = await AuthApi.getAttendance(ls.get("user").id, month, year);
            }

            if (response && response.status === true) {
                const attendanceData = response.data.map((row, index) => ({
                    id: index + 1,
                    ...row
                }));
                this.setState({
                    Attendance: attendanceData,
                });
            } else {
                console.error("Failed to fetch attendance data:", response.message);
            }
        } catch (error) {
            console.error("Error fetching attendance data:", error);
        }
    };


    fetchDailyLog = async (id) => {
        const { selectedDatejh } = this.state;
        const [year, month, day] = selectedDatejh.split('-');
        try {
            const roles = ls.get("roles");
            let response;

            if (roles && roles.includes("Super Admin")) {
                response = await AuthApi.getalldatadailylog(day, month, year);
            } else {
                response = await AuthApi.getdailylog(ls.get("user").id, day, month, year);
            }

            if (response && response.status === true) {
                const dailyLogData = response.data.map((row, index) => ({
                    id: index + 1,
                    // serial_no: index + 1,
                    ...row
                }));
                this.setState({
                    dailyLogData: dailyLogData,
                }, this.createChart);
            } else {
                console.error("Failed to fetch daily log data:", response.message);
            }
        } catch (error) {
            console.error("Error fetching daily log data:", error);
        }
    };






    handleShowDailyLog = () => {
        this.fetchDailyLog(ls.get("user").id);
        this.setState({ showMonthlyLog: false });
    };

    handleDateChange = (event) => {
        const selectedDate = event.target.value;
        this.setState({ selectedDate }, this.fetchData);
    };

    handledayChange = (event) => {
        const selectedDatejh = event.target.value;
        this.setState({ selectedDatejh }, this.fetchDailyLog);
    };

    handleShowMonthlyLog = () => {
        this.setState({ showMonthlyLog: true });
    };







    createChart = () => {
        const { dailyLogData } = this.state;

        const userLogs = {};
        const inToOutData = [];
        const breakData = [];

        dailyLogData.forEach(log => {
            const timeParts = log.time.split(':');
            const hours = parseInt(timeParts[0], 10);
            const minutes = parseInt(timeParts[1], 10);
            const seconds = parseInt(timeParts[2].split(' ')[0], 10);
            const period = timeParts[2].split(' ')[1];

            let timeIn24HourFormat = hours;
            if (period === 'PM' && hours !== 12) {
                timeIn24HourFormat += 12;
            } else if (period === 'AM' && hours === 12) {
                timeIn24HourFormat = 0;
            }

            const timeInHours = timeIn24HourFormat + minutes / 60 + seconds / 3600;

            if (!userLogs[log.firstname]) {
                userLogs[log.firstname] = [];
            }

            userLogs[log.firstname].push({
                time: timeInHours,
                type: log.type
            });
        });

        Object.keys(userLogs).forEach(userName => {
            const logs = userLogs[userName];
            for (let i = 0; i < logs.length - 1; i++) {
                const currentLog = logs[i];
                const nextLog = logs[i + 1];
                if (currentLog.type === 'IN' && nextLog.type === 'OUT') {
                    inToOutData.push({
                        x: [currentLog.time, nextLog.time],
                        y: userName
                    });
                } else if (currentLog.type === 'OUT' && nextLog.type === 'IN') {
                    breakData.push({
                        x: [currentLog.time, nextLog.time],
                        y: userName
                    });
                }
            }
        });

        if (this.chartInstance) {
            this.chartInstance.destroy();
        }

        const ctx = this.chartRef.current.getContext("2d");
        this.chartInstance = new Chart(ctx, {
            type: 'bar',
            data: {
                datasets: [
                    {
                        label: 'IN & OUT',
                        data: inToOutData,
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 2,
                        barThickness: 10,
                        borderSkipped: false
                    },
                    {
                        label: 'BREAKS',
                        data: breakData,
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 2,
                        barThickness: 10,
                        borderSkipped: false
                    }
                ]
            },
            options: {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'linear',
                        position: 'bottom',
                        min: 9,
                        max: 21,
                        ticks: {
                            stepSize: 1,
                            callback: function (value) {
                                const hour = Math.floor(value);
                                const period = hour >= 12 ? 'PM' : 'AM';
                                const hour12 = hour % 12 || 12;
                                return `${hour12}:00 ${period}`;
                            }
                        }
                    },
                    y: {
                        type: 'category',
                        labels: Object.keys(userLogs)
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            font: {
                                weight: 'bold'
                            },
                            generateLabels: function (chart) {
                                return [
                                    {
                                        text: 'IN & OUT',
                                        fillStyle: 'rgba(54, 162, 235, 0.6)',
                                        strokeStyle: 'rgba(54, 162, 235, 1)',
                                        lineWidth: 1
                                    },
                                    {
                                        text: 'BREAKS',
                                        fillStyle: 'rgba(255, 99, 132, 0.6)',
                                        strokeStyle: 'rgba(255, 99, 132, 1)',
                                        lineWidth: 1
                                    }
                                ];
                            }
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                const label = context.dataset.label || '';
                                const value = context.raw.x;
                                const startTime = value[0];
                                const endTime = value[1];

                                const formatTime = (time) => {
                                    const hour = Math.floor(time);
                                    const minute = Math.floor((time % 1) * 60);
                                    const period = hour >= 12 ? 'PM' : 'AM';
                                    const hour12 = hour % 12 || 12;
                                    return `${hour12}:${minute < 10 ? '0' : ''}${minute} ${period}`;
                                };

                                return `${label}: ${formatTime(startTime)} - ${formatTime(endTime)}`;
                            }
                        }
                    }
                }
            }
        });
    };

    handlePageSizeChangeMonthly = (pageSize) => {
        this.setState({ pageSizeMonthly: pageSize });
    };

    handlePageSizeChangeDaily = (pageSize) => {
        this.setState({ pageSizeDaily: pageSize });
    };

    handleChangeDaily(e) {
        const searchQueryDaily = e.target.value.toLowerCase();
        const suggestionsDaily = this.state.dailyLogData.filter((item) => {
            return (
                item.date.includes(searchQueryDaily) || item.firstname.toLowerCase().includes(searchQueryDaily)
            );
        });
        this.setState({ searchQueryDaily, suggestionsDaily });
    }

    handleChangeMonthly(e) {
        const searchQueryMonthly = e.target.value;
        const suggestionsMonthly = this.state.Attendance.filter((item) => {
            return (
                item.date.includes(searchQueryMonthly) || item.firstname.toLowerCase().includes(searchQueryMonthly)
            );
        });
        this.setState({ searchQueryMonthly, suggestionsMonthly });
    }

    handleSearchMonthly = () => {
        const filterEvents = this.state.Attendance.filter((item) => {
            return (
                item.date.includes(this.state.searchQueryMonthly) || item.firstname.toLowerCase().includes(this.state.searchQueryMonthly.toLowerCase())
            );
        });
        this.setState({ filterEvents });
    };

    handleSearchDaily = () => {
        const filterEventsDaily = this.state.dailyLogData.filter((item) => {
            return (
                item.date.includes(this.state.searchQueryDaily) || item.firstname.toLowerCase().includes(this.state.searchQueryDaily.toLowerCase())
            );
        });
        this.setState({ filterEventsDaily });
    };

    clearSearchMonthly = () => {
        this.setState({ searchQueryMonthly: "", suggestionsMonthly: [] }, this.handleSearchMonthly);
    };

    clearSearchDaily = () => {
        this.setState({ searchQueryDaily: "", suggestionsDaily: [] }, this.handleSearchDaily);
    };

    handleSuggestionClickMonthly = (suggestion) => {
        this.setState({ searchQueryMonthly: suggestion.firstname, suggestionsMonthly: [] }, this.handleSearchMonthly);
    };

    handleSuggestionClickDaily = (suggestion) => {
        this.setState({ searchQueryDaily: suggestion.firstname, suggestionsDaily: [] }, this.handleSearchDaily);
    };

    render() {
        const { columns, Attendance, selectedDate, showMonthlyLog, selectedDatejh, dailyLogColumns, dailyLogData, pageSizeMonthly, pageSizeDaily, searchQueryMonthly, searchQueryDaily, suggestionsMonthly, suggestionsDaily } = this.state;

        const filterEvents = this.state.filterEvents || this.state.Attendance;
        const filterEventsDaily = this.state.filterEventsDaily || this.state.dailyLogData;

        return (
            <div>
                <AuthCheckComponent authToken={this.props.authToken} />
                <Header
                    {...this.props}
                    authUser={this.props.authUser}
                    setAutUser={this.props.setAutUser}
                    component={
                        <div>
                            <Card style={{ marginBottom: '20px' }}>
                                <CardContent>
                                    <Breadcrumb
                                        {...this.props}
                                        primaryPageName="Attendance"
                                        isSecondaryPage={false}
                                        secondaryPageName="" />
                                </CardContent>
                            </Card>

                            <div
                                style={{
                                    position: "absolute",
                                    marginTop: "15px",
                                    marginInlineStart: "10px"
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    style={{ marginRight: '10px' }}
                                    onClick={this.handleShowDailyLog}
                                >
                                    Daily Log
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={this.handleShowMonthlyLog}
                                >
                                    Monthly Log
                                </Button>
                            </div>

                            {showMonthlyLog ? (
                                <Card style={{ padding: "10px" }}>
                                    <div style={{ height: "600px", marginBottom: "-70px" }}>
                                        <div style={{ marginBottom: "-60px", marginTop: "5%", marginRight: "85%" }}>
                                            <TextField
                                                id="month"
                                                label="Select month"
                                                variant="outlined"
                                                type="month"
                                                value={selectedDate}
                                                onChange={this.handleDateChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                        <TextField
                                            label="Search"
                                            value={searchQueryMonthly}
                                            onChange={this.handleChangeMonthly}
                                            style={{
                                                position: "absolute",
                                                right: "32px",
                                                marginTop: "15px"
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: searchQueryMonthly !== "",
                                                style: {
                                                    color: '#1b6e95',
                                                    transform: searchQueryMonthly !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)',
                                                    transition: 'transform 0.2s ease-in-out'
                                                }
                                            }}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: 'lightgray',
                                                    borderRadius: '4px',
                                                    height: "40px"
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {searchQueryMonthly && (
                                                            <ClearIcon onClick={this.clearSearchMonthly} style={{ cursor: 'pointer' }} />
                                                        )}
                                                        <SearchIcon onClick={this.handleSearchMonthly} style={{ cursor: 'pointer' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {searchQueryMonthly && suggestionsMonthly.length > 0 && (
                                            <Paper style={{
                                                position: 'absolute', right: '100px', marginTop: '62px', zIndex: 1, width: "209px", maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
                                            }}>
                                                {[...new Set(suggestionsMonthly.map(suggestion => suggestion.firstname))].map((firstname, index) => (
                                                    <MenuItem key={index} onClick={() => this.handleSuggestionClickMonthly({ firstname })} style={{
                                                        padding: '10px 20px',
                                                        cursor: 'pointer'
                                                    }}>
                                                        {firstname}
                                                    </MenuItem>
                                                ))}
                                            </Paper>
                                        )}
                                        <div style={{ height: 380, width: '100%', marginTop: "76px" }}>
                                            <DataGrid
                                                columns={columns}
                                                rows={filterEvents}
                                                pageSize={pageSizeMonthly}
                                                rowsPerPageOptions={[5, 10, 25, 100]}
                                                onPageChange={(newPage) => console.log(newPage)}
                                                onPageSizeChange={(newPageSize) => this.handlePageSizeChangeMonthly(newPageSize)}
                                            />
                                        </div>
                                    </div>
                                </Card>
                            ) : (
                                <Card style={{ padding: "10px" }}>
                                    <div style={{ paddingBottom: "50px" }}>
                                        <div style={{ marginBottom: '20px', textAlign: 'right' }}>
                                            <TextField
                                                id="date"
                                                label="Select date"
                                                variant="outlined"
                                                type="date"
                                                value={selectedDatejh}
                                                onChange={this.handledayChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{ marginRight: '10px' }}
                                            />
                                        </div>
                                        <div className='graphresponsive'>
                                            <canvas
                                                ref={this.chartRef}
                                                style={{
                                                    height: '400px',
                                                    width: '100%'
                                                }}
                                            />
                                        </div>
                                        <TextField
                                            label="Search"
                                            value={searchQueryDaily}
                                            onChange={this.handleChangeDaily}
                                            style={{
                                                position: "absolute",
                                                right: "32px",
                                                marginTop: "15px"
                                            }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: searchQueryDaily !== "",
                                                style: {
                                                    color: '#1b6e95',
                                                    transform: searchQueryDaily !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)',
                                                    transition: 'transform 0.2s ease-in-out'
                                                }
                                            }}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: 'lightgray',
                                                    borderRadius: '4px',
                                                    height: "40px"
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {searchQueryDaily && (
                                                            <ClearIcon onClick={this.clearSearchDaily} style={{ cursor: 'pointer' }} />
                                                        )}
                                                        <SearchIcon onClick={this.handleSearchDaily} style={{ cursor: 'pointer' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {searchQueryDaily && suggestionsDaily.length > 0 && (
                                            <Paper style={{
                                                position: 'absolute', right: '100px', marginTop: '62px', zIndex: 1, width: "209px", maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
                                            }}>
                                                {[...new Set(suggestionsDaily.map(suggestion => suggestion.firstname))].map((firstname, index) => (
                                                    <MenuItem key={index} onClick={() => this.handleSuggestionClickDaily({ firstname })} style={{
                                                        padding: '10px 20px',
                                                        cursor: 'pointer'
                                                    }}>
                                                        {firstname}
                                                    </MenuItem>
                                                ))}
                                            </Paper>
                                        )}
                                        <div style={{ height: 400, width: '100%', marginTop: "77px", marginBottom: "25px" }}>
                                            <DataGrid
                                                rows={filterEventsDaily}
                                                columns={dailyLogColumns}
                                                pageSize={pageSizeDaily}
                                                rowsPerPageOptions={[5, 10, 25, 100]}
                                                onPageSizeChange={(newPageSize) => this.handlePageSizeChangeDaily(newPageSize)}
                                            />
                                        </div>
                                    </div>
                                </Card>
                            )}
                        </div>
                    }
                />
                <Footer {...this.props} />
            </div>
        );
    }
}

export default Attendance;