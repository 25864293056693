import React, { Component } from 'react';
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import AuthApi from '../../Services/Authapi';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableComponent from '../../Layouts/TableComponent';
import swal from 'sweetalert';
import TextField from "@material-ui/core/TextField";
import AuthCheckComponent from '../../Layouts/Token';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

class listdesignation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            departmentsData: [],
            count: 0,
            pageStatus: false,
            searchQuery: "",
            suggestions: [],
            tableTh: [
                { id: 'designation', numeric: false, disablePadding: true, label: 'Designation Name', sortable: true },
                { id: 'paid_leave_allowed', numeric: false, disablePadding: true, label: 'Paid Leave Allowed', sortable: true },
                { id: 'created_at', numeric: false, disablePadding: true, label: 'Created On', sortable: true },
                { id: 'actions', numeric: false, disablePadding: true, label: 'Actions', sortable: false }
            ]
        }
        this.departmentsData = this.departmentsData.bind(this);
        this.Departmentdelete = this.Departmentdelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
    }

    componentWillMount() {
        if (this.props && this.props.authToken === false) {
            // this.props.history.push('/login');
        }
        this.departmentsData();
    }

    componentWillReceiveProps(props) {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }
    }

    departmentsData = async (e) => {
        let qulif = await AuthApi.Departmentshow();

        if (qulif && qulif.status === true) {
            this.setState({
                departmentsData: qulif.data,
                count: qulif.data.length
            })
        }
    }

    async Departmentdelete(id) {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Cancel", "Yes"]
        }).then(async (confirm) => {
            if (confirm) {
                let currentEvent = await AuthApi.Departmentdelete(id);
                if (currentEvent && currentEvent.status === true) {
                    this.setState({
                        pageStatus: true
                    })
                    this.departmentsData();
                    setTimeout(
                        () => this.setState({ pageStatus: false }),
                        500
                    );
                } else {
                }
            }
        });
    }

    handleChange(e) {
        const searchQuery = e.target.value;
        const suggestions = this.state.departmentsData.filter((item) => {
            return (
                item.designation.toLowerCase().includes(searchQuery.toLowerCase())
            );
        });
        this.setState({ searchQuery, suggestions });
    }

    handleSearch = () => {
        const filterUser = this.state.departmentsData.filter((item) => {
            return (
                item.designation.toLowerCase().includes(this.state.searchQuery.toLowerCase())
            );
        });
        this.setState({ filterUser });
    };

    clearSearch = () => {
        this.setState({ searchQuery: "", suggestions: [] }, this.handleSearch);
    };

    handleSuggestionClick = (suggestion) => {
        this.setState({ searchQuery: suggestion.designation, suggestions: [] }, this.handleSearch);
    };

    render() {
        const classes = this.props;
        const filterUser = this.state.filterUser || this.state.departmentsData;
        const permissions = (this.props.authUser && Array.isArray(this.props.authUser.userAccess)) ? this.props.authUser.userAccess : [];
        return (
            <div>
                <AuthCheckComponent authToken={this.props.authToken} />
                <Header
                    {...this.props}
                    authUser={this.props.authUser}
                    setAutUser={this.props.setAutUser}
                    component={
                        <div style={{ marginBottom: "11%" }}>
                            <Card className={classes.root} style={{ marginBottom: '3%' }}>
                                <CardContent>
                                    <Breadcrumb
                                        {...this.props}
                                        primaryPageName="Designation"
                                        primaryPageLink="/designation"
                                        isSecondaryPage={false}
                                        secondaryPageName="" />
                                </CardContent>
                            </Card>
                            <TextField
                                label="Search"
                                value={this.state.searchQuery}
                                onChange={this.handleChange}
                                style={{
                                    position: "absolute",
                                    right: "121px",
                                    marginTop: "21px"
                                }}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: this.state.searchQuery !== "",
                                    style: {
                                        color: '#1b6e95',
                                        transform: this.state.searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)', 
                                        transition: 'transform 0.2s ease-in-out'
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        backgroundColor: 'lightgray',
                                        borderRadius: '4px',
                                        height: "40px"
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {this.state.searchQuery && (
                                                <ClearIcon onClick={this.clearSearch} style={{ cursor: 'pointer' }} />
                                            )}
                                            <SearchIcon onClick={this.handleSearch} style={{ cursor: 'pointer' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {this.state.searchQuery && this.state.suggestions.length > 0 && (
                                <Paper
                                    style={{
                                        position: 'absolute',
                                        right: '191px',
                                        marginTop: '62px',
                                        zIndex: 1,
                                        width: "207px",
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
                                    }}>
                                    {this.state.suggestions.map((suggestion, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={() => this.handleSuggestionClick(suggestion)}
                                            style={{
                                                padding: '10px 20px',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {suggestion.designation}
                                        </MenuItem>
                                    ))}
                                </Paper>
                            )}
                            <TableComponent
                                {...this.props}
                                tableTh={this.state.tableTh}
                                tableData={filterUser}
                                tableCount={filterUser.length}
                                tablePagestatus={this.state.pageStatus}
                                colNameToShow={['designation', 'paid_leave_allowed', 'created_at']}
                                openPopUp={false}
                                removeRow={this.Departmentdelete}
                                actionBtns={[permissions.includes("designation.Edit") && ('update'), permissions.includes("designation.Delete") && ('delete1')]}
                                modelName={'Designation'}
                                addRoute={'/designation/add'}
                                updateRoute={'/designation/edit'}
                                openPopUpUpdate={false}
                            />
                        </div>
                    } />
                <Footer {...this.props} />
            </div >
        )
    }
}

export default listdesignation;