import React, { Component } from 'react';
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer';
import AuthApi from '../../Services/Authapi';
import TableComponent from '../../Layouts/TableComponent';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import swal from 'sweetalert';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ls from "local-storage";
import AuthCheckComponent from '../../Layouts/Token';
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

class Upcoming extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upcoming: [],
            count: 0,
            searchQuery: "",
            filteredEvents: [],
            suggestions: [],
            pageStatus: false,
            tableTh: [
                { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
                { id: 'date', numeric: false, disablePadding: true, label: 'Date', sortable: true },
                { id: 'created_at', numeric: false, disablePadding: true, label: 'Created On', sortable: true },
                { id: 'actions', numeric: false, disablePadding: true, label: 'Actions', sortable: false }
            ]
        }
        this.eventData = this.eventData.bind(this);
        this.removeEvent = this.removeEvent.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
    }

    componentDidMount() {
        if (!this.props.authToken) {
            // this.props.history.push("/login");
        }
        this.eventData();
    }

    eventData = async (e) => {
        let events = await AuthApi.getAllEvents();
        // console.log(events)
        if (events && events.status === true) {
            this.setState({
                upcoming: events.data,
                filteredEvents: events.data,
                count: events.data.length
            })
        }
    }

    async removeEvent(id) {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Cancel", "Yes"]
        }).then(async (confirm) => {
            if (confirm) {
                let currentEvent = await AuthApi.AlleventDelete(id);
                if (currentEvent && currentEvent.status === true) {
                    this.setState({
                        pageStatus: true
                    })
                    this.eventData();
                    setTimeout(
                        () => this.setState({ pageStatus: false }),
                        500
                    );
                } else {
                }
            }
        });
    }

    
    handleChange(e) {
        const searchQuery = e.target.value;
        const suggestions = this.state.upcoming.filter((item) => {
            return (
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.created_at.toLowerCase().includes(searchQuery.toLowerCase())
            );
        });
        this.setState({ searchQuery, suggestions });
    }

    handleSearch() {
        const { searchQuery, upcoming } = this.state;
        const filteredEvents = upcoming.filter((item) => {
            return (
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.created_at.toLowerCase().includes(searchQuery.toLowerCase())
            );
        });
        this.setState({ filteredEvents });
        // console.log("Search triggered for:", searchQuery);
    }

    clearSearch = () => {
        this.setState({ searchQuery: "", suggestions: [] }, this.handleSearch);
    };

    handleSuggestionClick = (suggestion) => {
        this.setState({ searchQuery: suggestion.name, suggestions: [] }, this.handleSearch);
    };

    render() {
        const classes = this.props;
        const { filteredEvents, suggestions, searchQuery } = this.state;
        // const admin = ls("roles") === "Admin"
        const permissions = (this.props.authUser && Array.isArray(this.props.authUser.userAccess)) ? this.props.authUser.userAccess : [];
        return (
            <div>
                <AuthCheckComponent authToken={this.props.authToken} />
                <Header
                    {...this.props}
                    authUser={this.props.authUser}
                    setAutUser={this.props.setAutUser}
                    component={
                        <div style={{ marginBottom: "11%" }}>
                            <Card className={classes.root} style={{ marginBottom: '3%' }}>
                                <CardContent>
                                    <Breadcrumb
                                        {...this.props}
                                        primaryPageName="Upcoming-Events"
                                        primaryPageLink="/upcoming"
                                        isSecondaryPage={false}
                                        secondaryPageName="" />
                                </CardContent>
                            </Card>

                            <TextField
                                label="Search"
                                value={searchQuery}
                                onChange={this.handleChange}
                                style={{
                                    position: "absolute",
                                    right: "121px",
                                    marginTop: "21px"
                                }}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: searchQuery !== "",
                                    style: {
                                        color: '#1b6e95',
                                        transform: searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)',
                                        transition: 'transform 0.2s ease-in-out'
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        backgroundColor: 'lightgray',
                                        borderRadius: '4px',
                                        height: "40px"
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {this.state.searchQuery && (
                                                <ClearIcon onClick={this.clearSearch} style={{ cursor: 'pointer' }} />
                                            )}
                                            <SearchIcon onClick={this.handleSearch} style={{ cursor: 'pointer' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {searchQuery && suggestions.length > 0 && (
                                <Paper
                                    style={{
                                        position: 'absolute',
                                        right: '188px',
                                        marginTop: '62px',
                                        zIndex: 1,
                                        width: "209px",
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
                                    }}
                                >
                                    {[...new Set(suggestions.map(suggestion => suggestion.name))].map((name, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={() => this.handleSuggestionClick({ name })}
                                            style={{
                                                padding: '10px 20px',
                                                cursor: 'pointer'
                                            }}
                                            onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                                            onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Paper>
                            )}
                            <TableComponent
                                {...this.props}
                                tableTh={this.state.tableTh}
                                tableData={filteredEvents}
                                tableCount={filteredEvents.length}
                                tablePagestatus={this.state.pageStatus}
                                colNameToShow={['name', 'date', 'created_at']}
                                openPopUp={false}
                                removeRow={this.removeEvent}
                                actionBtns={[permissions.includes("Upcomingevent.Edit") && ('update'), permissions.includes("Upcomingevent.Delete") && ('delete')]}
                                modelName={'Upcoming Event'}
                                addRoute={'/upcoming/add'}
                                updateRoute={'/upcoming/edit'}
                                openPopUpUpdate={false}
                            />
                        </div>
                    } />
                <Footer {...this.props} />
            </div>
        )
    }
}
export default Upcoming;