import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Grid, Card, CardContent, Typography, Paper, Button, MobileStepper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const DragAndDropSections = ({
  sections,
  onDragEnd,
  classes,
  theme,
  permissions = [],
  active,
  activeStep,
  handleStepChange,
  handleNext,
  handleBack,
  maxStep,
  active1,
  activeStep1,
  handleStepChange1,
  handleNext1,
  handleBack1,
  maxStep1,
  pendingLeaves,
  approvedLeaves,
  handleLeaveClick,
  upcomingHolidays,
  timeEntries,
  userleave,
  handleTimeEntryClick,
  usersWithCurrentMonthDOJ,
  isSuperAdmin,
  pendingLeavesHeader
}) => {

  const permissionsArray = Array.isArray(permissions) ? permissions : [];

  // Filter sections to only include those with data
  const filteredSections = sections.filter(section => {
    if (section.id === 'Upcomingevent' && permissionsArray.includes("Dashboard.Upcomingevent") && active.length > 0) return true;
    if (section.id === 'bfffday' && permissionsArray.includes("Dashboard.Birthday") && active1.length > 0) return true;
    if (section.id === 'pendingLeaves' && (pendingLeaves.length > 0 || approvedLeaves.length > 0)) return true;
    if (section.id === 'upcomingHolidays' && upcomingHolidays.length > 0) return true;
    if (section.id === 'timeEntries' && isSuperAdmin && timeEntries.data.length > 0) return true;
    if (section.id === 'userleave' && isSuperAdmin && userleave.length > 0) return true;
    if (section.id === 'newJoinedEmployees' && isSuperAdmin && usersWithCurrentMonthDOJ.length > 0) return true;
    return false;
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="sections">
        {(provided) => (
          <Grid container spacing={2} style={{ paddingBottom: "100px" }} ref={provided.innerRef} {...provided.droppableProps}>
            {filteredSections.map((section, index) => (
              <Draggable key={section.id} draggableId={section.id} index={index}>
                {(provided) => (
                  <Grid item xs={6} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                    {section.id === 'Upcomingevent' && permissionsArray.includes("Dashboard.Upcomingevent") && active.length > 0 && (
                      <Card style={{ marginBottom: "3%", height: "95%" }} className={classes.upcomingCard}>
                        <div className={classes.pendingLeavesHeader}>
                          <Typography variant="h5">Upcoming Event</Typography>
                        </div>
                        <CardContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
                          <Grid item xs={6}>
                            <div className="slider" style={{ width: "200%" }}>
                              {active.map((item, index) => (
                                <div className={index === activeStep ? "slide active" : "slide"} key={index}>
                                  {index === activeStep && (
                                    <div>
                                      <Paper square elevation={0} className="top-left">
                                        <h3><span> {item.name}</span></h3>
                                        <h3><span>{item.date}</span></h3>
                                        <h5><span>{item.description}</span></h5>
                                      </Paper>
                                      <AutoPlaySwipeableViews
                                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                        index={activeStep}
                                        onChangeIndex={handleStepChange}
                                        enableMouseEvents
                                      >
                                        {active.map((step, index) => (
                                          <div key={step.name}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                              <img src={step.image} alt={step.name} width="100%" height={250} />
                                            ) : null}
                                          </div>
                                        ))}
                                      </AutoPlaySwipeableViews>
                                      <MobileStepper
                                        steps={maxStep}
                                        position="static"
                                        variant="text"
                                        activeStep={activeStep}
                                        nextButton={
                                          <Button size="small" onClick={handleNext} disabled={activeStep === maxStep - 1}>
                                            Next
                                            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                          </Button>
                                        }
                                        backButton={
                                          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                            Back
                                          </Button>
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </Grid>
                        </CardContent>
                      </Card>
                    )}

                    {section.id === 'bfffday' && permissionsArray.includes("Dashboard.Birthday") && active1.length > 0 && (
                      <Card style={{ border: "solid rgb(165 163 163 / 87%) 1px" }}>
                        <div className={classes.pendingLeavesHeader}>
                          <Typography variant="h5">{active1.length} - Birthdays Today</Typography>
                        </div>
                        <CardContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
                          <Grid item xs={6} style={{ marginTop: "-29px" }}>
                            <div className="slider" style={{ width: "200%" }}>
                              {active1.map((item, index) => (
                                <div className={index === activeStep1 ? "slide active" : "slide"} key={index}>
                                  {index === activeStep1 && (
                                    <div>
                                      <Paper square elevation={0} className="top-left">
                                        <div className="" style={{ display: "flex", position: "relative", top: "50%", left: "209%" }}>
                                          <div>
                                            <img className="bday" src={item.photo} alt={item.firstName} />
                                          </div>
                                          <div style={{ marginTop: "-5px" }}>
                                            <h3 style={{ color: "black", marginLeft: "10px" }}>{item.firstName}</h3>
                                          </div>
                                        </div>
                                      </Paper>
                                      <AutoPlaySwipeableViews
                                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                        index={activeStep1}
                                        onChangeIndex={handleStepChange1}
                                        enableMouseEvents
                                      >
                                        {active1.map((step, index) => (
                                          <div key={step.name}>
                                            {Math.abs(activeStep1 - index) <= 2 ? (
                                              <div className={"root"}>
                                                <div style={{ opacity: "0" }}>
                                                  <h1>h</h1>
                                                  <h1>h</h1>
                                                  <h2>j</h2>
                                                  <p>j</p>
                                                  <p>j</p>
                                                  <p>j</p>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        ))}
                                      </AutoPlaySwipeableViews>
                                      <MobileStepper
                                        steps={maxStep1}
                                        position="static"
                                        variant="text"
                                        activeStep={activeStep1}
                                        nextButton={
                                          <Button size="small" onClick={handleNext1} disabled={activeStep1 === maxStep1 - 1}>
                                            Next
                                            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                          </Button>
                                        }
                                        backButton={
                                          <Button size="small" onClick={handleBack1} disabled={activeStep1 === 0}>
                                            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                            Back
                                          </Button>
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </Grid>
                        </CardContent>
                      </Card>
                    )}

                    {section.id === 'pendingLeaves' && (pendingLeaves.length > 0 || approvedLeaves.length > 0) && (
                      <Card className={classes.pendingLeavesCard}>
                        <div className={classes.pendingLeavesHeader}>
                          <Typography variant="h5">{pendingLeavesHeader}</Typography>
                        </div>
                        <CardContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
                          <Table className={classes.pendingLeavesTable}>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Name</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Leave Type</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Date Range</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>No Of Days</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Reason For Leave</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {pendingLeaves.map((leave, index) => (
                                <TableRow key={index} onClick={() => handleLeaveClick(leave)} style={{ cursor: 'pointer' }} className="hoverRow">
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.firstName}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.leaveType}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.date_range}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.no_of_days}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.ticket_mesasge}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.status}</TableCell>
                                </TableRow>
                              ))}
                              {approvedLeaves.map((leave, index) => (
                                <TableRow key={index} onClick={() => handleLeaveClick(leave)} style={{ cursor: 'pointer' }} className="hoverRow">
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.firstName}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.leaveType}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.date_range}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.no_of_days}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.ticket_mesasge}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{leave.status}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Card>
                    )}

                    {section.id === 'upcomingHolidays' && upcomingHolidays.length > 0 && (
                      <Card style={{ border: "solid rgb(165 163 163 / 87%) 1px" }} className={classes.holidayLeavesCard}>
                        <div className={classes.pendingLeavesHeader}>
                          <Typography variant="h5">Upcoming Holidays</Typography>
                        </div>
                        <CardContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
                          <Table className={classes.pendingLeavesTable}>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Holiday Name</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Date</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Total Days</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {upcomingHolidays.map((holiday, index) => {
                                const [day, month, year] = holiday.date.split("-").map(Number);
                                const holidayDate = new Date(year, month - 1, day);
                                const dayOfWeek = holidayDate.toLocaleDateString('en-US', { weekday: 'long' });

                                const formattedDate = `${holiday.date}, ${dayOfWeek}`;

                                return (
                                  <TableRow key={index}>
                                    <TableCell className={classes.pendingLeavesTableCell}>{holiday.name}</TableCell>
                                    <TableCell className={classes.pendingLeavesTableCell}>
                                      {formattedDate}
                                    </TableCell>
                                    <TableCell className={classes.pendingLeavesTableCell}>{holiday.no_of_days}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Card>
                    )}

                    {section.id === 'timeEntries' && isSuperAdmin && timeEntries.data.length > 0 && (
                      <Card className={classes.holidayLeavesCard} >
                        <div className={classes.pendingLeavesHeader}>
                          <Typography variant="h5">Time Entries</Typography>
                        </div>
                        <CardContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
                          <Table className={classes.pendingLeavesTable}>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Employee ID</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Name</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Date</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>In Time</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Out Time</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Durations</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {timeEntries.data.map((entry, index) => (
                                <TableRow key={index} onClick={() => handleTimeEntryClick(entry)} style={{ cursor: 'pointer' }} className="hoverRow">
                                  <TableCell className={classes.pendingLeavesTableCell}>{entry.name} </TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{entry.Name}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{entry.date}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{entry.in_time}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{entry.out_time}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{entry.duration}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{entry.is_approved}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Card>
                    )}

                    {section.id === 'userleave' && isSuperAdmin && userleave.length > 0 && (
                      <Card className={classes.holidayLeavesCard} >
                        <div className={classes.pendingLeavesHeader}>
                          <Typography variant="h5">Users Today Status</Typography>
                        </div>
                        <CardContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
                          <Table className={classes.pendingLeavesTable}>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Employee ID</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Name</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {userleave.map((entry, index) => (
                                <TableRow key={index}  style={{ cursor: 'pointer' }} className="hoverRow">
                                  <TableCell className={classes.pendingLeavesTableCell}>{entry.empid} </TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{entry.name}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{entry.status}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Card>
                    )}

                    {section.id === 'newJoinedEmployees' && isSuperAdmin && usersWithCurrentMonthDOJ.length > 0 && (
                      <Card className={classes.newJoined}>
                        <div className={classes.pendingLeavesHeader}>
                          <Typography variant="h5">New Joined Employees Of This Month</Typography>
                        </div>
                        <CardContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Employee ID</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Name</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Birthday</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Date Of Join</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Designation</TableCell>
                                <TableCell className={classes.pendingLeavesTableHeaderCell}>Gender</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {usersWithCurrentMonthDOJ.map((user, index) => (
                                <TableRow key={index}>
                                  <TableCell className={classes.pendingLeavesTableCell}>{user.name}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{user.firstName}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{user.dob}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{user.doj}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{user.designation}</TableCell>
                                  <TableCell className={classes.pendingLeavesTableCell}>{user.gender}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Card>
                    )}

                  </Grid>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropSections;