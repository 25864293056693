import React, { Component } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import AuthApi from "../../Services/Authapi";
import TableComponent from "../../Layouts/TableComponent";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import ls from "local-storage";
import AuthCheckComponent from '../../Layouts/Token';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { Dialog, DialogTitle, DialogContent, Typography, MenuItem, Paper, FormControl, InputLabel, Select } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./Leave.css";

class Leave extends Component {
  constructor(props) {
    super(props);
    const currentYear = moment().year();
    const currentMonth = moment().month();
    const startYear = currentMonth >= 3 ? currentYear : currentYear - 1; // Financial year starts in April
    const endYear = startYear + 1;

    this.state = {
      leaves: [],
      pageStatus: false,
      searchQuery: "",
      count: 0,
      suggestions: [],
      tableTh: [
        {
          id: "name",
          numeric: false,
          disablePadding: true,
          label: "Holiday Leaves",
          sortable: true,
        },
        {
          id: "date",
          numeric: false,
          disablePadding: true,
          label: "Date",
          sortable: true,
        },
        {
          id: "no",
          numeric: false,
          disablePadding: true,
          label: "Total Days",
          sortable: true,
        },
        {
          id: "actions",
          numeric: false,
          disablePadding: true,
          label: "Actions",
          sortable: false,
        },
      ],
      openHolidayModal: false,
      holidayDetails: null,
      selectedYear: `${startYear}-${endYear}`,
      financialYears: this.generateFinancialYears(startYear, 5), // Generate 5 years
    };
    this.leaveData = this.leaveData.bind(this);
    this.removeLeaves = this.removeLeaves.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
  }

  componentWillMount() {
    if (this.props && this.props.authToken === false) {
      // this.props.history.push("/login");
    }
    this.leaveData();
  }

  componentDidMount() {
    const { location } = this.props;
    const [startYear, endYear] = this.state.selectedYear.split('-');
    const startDate = moment(`${startYear}-04-01`, "YYYY-MM-DD");
    const endDate = moment(`${endYear}-03-31`, "YYYY-MM-DD");

    this.leaveData().then(() => {
      const holidaysForYear = this.state.leaves.filter(holiday => {
        const holidayDate = moment(holiday.date, "DD-MM-YYYY");
        return holidayDate.isBetween(startDate, endDate, null, '[]');
      });
      this.setState({ filterUser: holidaysForYear });
    });

    if (location.state && location.state.date && sessionStorage.getItem('openHolidayModal') === 'true') {
      this.leaveData(location.state.date);
      sessionStorage.removeItem('openHolidayModal');
    }
  }

  componentWillReceiveProps(props) {
    if (props && props.authToken === false) {
      // props.history.push("/login");
    }
  }

  leaveData = async (dateRange) => {
    let leaves = await AuthApi.getLeaves();
    if (leaves && leaves.status === true) {
      const currentDate = moment();
      const sortedLeaves = leaves.data.sort((a, b) => {
        const dateA = moment(a.date, "DD-MM-YYYY");
        const dateB = moment(b.date, "DD-MM-YYYY");
        if ((dateA.isAfter(currentDate) && dateB.isAfter(currentDate)) || (dateA.isBefore(currentDate) && dateB.isBefore(currentDate))) {
          return Math.abs(dateA.diff(currentDate)) - Math.abs(dateB.diff(currentDate));
        }
        if (dateA.isAfter(currentDate) && dateB.isBefore(currentDate)) {
          return -1;
        }
        if (dateA.isBefore(currentDate) && dateB.isAfter(currentDate)) {
          return 1;
        }
        if (dateA.isSame(currentDate, 'day')) {
          return -1;
        }
        if (dateB.isSame(currentDate, 'day')) {
          return 1;
        }
        return 0;
      });
      this.setState({
        leaves: sortedLeaves,
        count: sortedLeaves.length,
      }, () => {
        if (dateRange) {
          this.matchHolidayDateRange(dateRange);
        }
      });
    }
  };

  async removeLeaves(id) {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
    }).then(async (confirm) => {
      if (confirm) {
        let currentLeave = await AuthApi.leaveDelete(id);
        if (currentLeave && currentLeave.status === true) {
          this.setState({
            pageStatus: true,
          });
          this.leaveData();
          setTimeout(() => this.setState({ pageStatus: false }), 500);
        }
      }
    });
  }

  handleChange(e) {
    const searchQuery = e.target.value;
    const suggestions = this.state.leaves.filter((item) => {
      return (
        (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.date && item.date.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    });
    this.setState({ searchQuery, suggestions });
  }

  handleSearch = () => {
    const filterUser = this.state.leaves.filter((item) => {
      return (
        (item.name && item.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())) ||
        (item.date && item.date.toLowerCase().includes(this.state.searchQuery.toLowerCase()))
      );
    });
    this.setState({ filterUser });
  };

  clearSearch = () => {
    this.setState({ searchQuery: "", suggestions: [] }, this.handleSearch);
  };

  handleSuggestionClick = (suggestion) => {
    this.setState({ searchQuery: suggestion.name, suggestions: [] }, this.handleSearch);
  };


  matchHolidayDateRange = (date) => {
    const { leaves } = this.state;
    const matchedHoliday = leaves.find(leave => leave.date === date);
    if (matchedHoliday) {
      this.setState({
        holidayDetails: matchedHoliday,
        openHolidayModal: true,
      });
    }
  };


  handleCloseModal = () => {
    this.setState({ openHolidayModal: false });
  };

  formatDate = (date) => {
    const formats = ["YYYY-MM-DD", "DD-MM-YYYY", "MM-DD-YYYY", "YYYY/MM/DD", "DD/MM/YYYY", "MM/DD/YYYY"];
    let formattedDate = moment(date, formats, true);
    if (!formattedDate.isValid()) {
      console.error("Invalid date format:", date);
      return "Invalid date";
    }
    return formattedDate.format("DD-MM-YYYY, dddd");
  };

  generateFinancialYears(startYear, numberOfYears) {
    const years = [];
    for (let i = 0; i < numberOfYears; i++) {
      const yearStart = startYear - i;
      const yearEnd = yearStart + 1;
      years.push(`${yearStart}-${yearEnd}`);
    }
    return years;
  }

  handleYearChange(event) {
    this.setState({ selectedYear: event.target.value }, this.filterHolidaysByYear);
  }

  filterHolidaysByYear() {
    const { selectedYear, leaves } = this.state;
    const [startYear, endYear] = selectedYear.split('-');
    const startDate = moment(`${startYear}-04-01`, "YYYY-MM-DD");
    const endDate = moment(`${endYear}-03-31`, "YYYY-MM-DD");

    const holidaysForYear = leaves.filter(holiday => {
      const holidayDate = moment(holiday.date, "DD-MM-YYYY");
      return holidayDate.isBetween(startDate, endDate, null, '[]');
    });

    this.setState({ filterUser: holidaysForYear });
  }

  render() {
    const classes = this.props;
    const filterUser = this.state.filterUser || this.state.leaves;
    const role = ls("roles") === "Super Admin";
    let tableHeaders = this.state.tableTh;
    if (!role) {
      tableHeaders = tableHeaders.map((header) => {
        if (header.id === "actions") {
          return { ...header, label: "" };
        }
        return header;
      });
    }
    const permissions = (this.props.authUser && Array.isArray(this.props.authUser.userAccess)) ? this.props.authUser.userAccess : [];
    const actionButtons = role ? [permissions.includes("Holidays.Edit") && ('update'), permissions.includes("Holidays.Delete") && ('delete')] : null;

    const [startYear, endYear] = this.state.selectedYear.split('-');
    const startDate = moment(`${startYear}-04-01`, "YYYY-MM-DD");
    const endDate = moment(`${endYear}-03-31`, "YYYY-MM-DD");
    const holidaysForYear = filterUser.filter(holiday => {
      const holidayDate = moment(holiday.date, "DD-MM-YYYY");
      return holidayDate.isBetween(startDate, endDate, null, '[]');
    });

    const currentYear = moment().year();
    const currentMonth = moment().month();
    const currentStartYear = currentMonth >= 3 ? currentYear : currentYear - 1;
    const isCurrentYear = this.state.selectedYear === `${currentStartYear}-${currentStartYear + 1}`;

    return (
      <div>
        <AuthCheckComponent authToken={this.props.authToken} />
        <Header
          {...this.props}
          authUser={this.props.authUser}
          setAutUser={this.props.setAutUser}
          component={
            <div style={{ marginBottom: "11%" }} >
              <Card className={classes.root} style={{ marginBottom: "3%" }}>
                <CardContent>
                  <Breadcrumb
                    {...this.props}
                    primaryPageName="Holidays List"
                    primaryPageLink="/leaves"
                    isSecondaryPage={false}
                    secondaryPageName=""
                  />
                </CardContent>
              </Card>
              {/* {/ <div className="haka"> /} */}
              <TextField
                className="hardik"
                label="Search"
                value={this.state.searchQuery}
                onChange={this.handleChange}
                style={{
                  position: "absolute",
                  right: "121px",
                  marginTop: "55px"
                }}
                variant="outlined"
                InputLabelProps={{
                  shrink: this.state.searchQuery !== "",
                  style: {
                    color: '#1b6e95',
                    transform: this.state.searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)',
                    transition: 'transform 0.2s ease-in-out'
                  }
                }}
                InputProps={{
                  style: {
                    backgroundColor: 'lightgray',
                    borderRadius: '4px',
                    height: "40px"
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {this.state.searchQuery && (
                        <ClearIcon onClick={this.clearSearch} style={{ cursor: 'pointer' }} />
                      )}
                      <SearchIcon onClick={this.handleSearch} style={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl variant="outlined" style={{ marginTop: "55px", position: "absolute", right: "390px" }}>
                <InputLabel>Financial Year</InputLabel>
                <Select
                  value={this.state.selectedYear}
                  onChange={this.handleYearChange}
                  label="Financial Year"
                  style={{
                    height: "40px"
                  }}
                >
                  {this.state.financialYears.map((year, index) => (
                    <MenuItem key={index} value={year} >
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* {/ </div> /} */}
              {this.state.searchQuery && this.state.suggestions.length > 0 && (
                <Paper
                  style={{
                    position: 'absolute',
                    right: '188px',
                    marginTop: '95px',
                    zIndex: 1,
                    width: "225px",
                    maxHeight: '200px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
                  }}>
                  {[...new Set(this.state.suggestions.map(suggestion => suggestion.name))].map((name, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => this.handleSuggestionClick({ name })}
                      style={{
                        padding: '10px 20px',
                        cursor: 'pointer'
                      }}
                      onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                      onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Paper>
              )}
              <Typography variant="h6" style={{ color: '#1b6e95', marginTop: "-12px", marginBottom: "15px" }}>
                Total Holidays Of {isCurrentYear ? "Current Year" : this.state.selectedYear} : {holidaysForYear.length}
              </Typography>

              <TableComponent
                {...this.props}
                tableTh={tableHeaders}
                tableData={filterUser.map(item => ({
                  ...item,
                  date: this.formatDate(item.date)
                }))}
                tableCount={filterUser.length}
                tablePagestatus={this.state.pageStatus}
                colNameToShow={["name", "date", "no_of_days"]}
                openPopUp={false}
                removeRow={this.removeLeaves}
                actionBtns={actionButtons}
                modelName={"Holidays"}
                addRoute={"/leaves/add"}
                updateRoute={"/leaves/edit"}
                openPopUpUpdate={false}
              />
            </div>
          }
        />
        <Footer {...this.props} />

        {this.state.openHolidayModal && (
          <Dialog
            open={this.state.openHolidayModal}
            onClose={this.handleCloseModal}
            maxWidth="xs"
            fullWidth
            PaperProps={{
              style: {
                padding: "10px",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                width: "370px",
              },
            }}
          >
            <div style={{ position: "relative" }}>
              <DialogTitle
                style={{
                  textAlign: "center",
                  padding: "5px 0",
                  backgroundColor: "#1b6e95",
                  color: "#fff",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                Holiday Details
              </DialogTitle>
              <CloseIcon
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                  color: "#fff",
                  backgroundColor: "#f44336",
                  borderRadius: "50%",
                  padding: "2px",
                }}
                onClick={this.handleCloseModal}
              />
              <DialogContent>
                {this.state.holidayDetails ? (
                  <div>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                      <tbody>
                        <tr>
                          <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Name</b></td>
                          <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                          <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{this.state.holidayDetails.name}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>No Of Days</b></td>
                          <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                          <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{this.state.holidayDetails.no_of_days}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Date Range</b></td>
                          <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                          <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{this.state.holidayDetails.date}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Typography variant="body2">No details available</Typography>
                )}
              </DialogContent>
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}

export default withRouter(Leave); 
