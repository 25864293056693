import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AuthCheckComponent from '../../Layouts/Token';
import { useLocation } from 'react-router-dom';
import Authapi from '../../Services/Authapi';
import swal from 'sweetalert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const OfferLatterAdd = (props) => {
    const location = useLocation();
    const { templates, templateName: initialTemplateName } = location.state || {};
    const [templateData, setTemplateData] = useState(templates || []);
    const [templateName, setTemplateName] = useState(initialTemplateName || '');
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (templateData.length > 0) {
            const initialFormData = {};
            templateData.forEach(template => {
                initialFormData[template.label] = '';
            });
            setFormData(initialFormData);
        }
    }, [templateData]);
    
    const handleInputChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        setFormData(prevState => {
            if (type === 'checkbox') {
                const currentValues = prevState[name] ? prevState[name].split(', ') : [];
                const updatedValues = checked
                    ? [...currentValues, value]
                    : currentValues.filter(v => v !== value);

                return {
                    ...prevState,
                    [name]: updatedValues.join(', ')
                };
            } else if (type === 'file') {
                return {
                    ...prevState,
                    [name]: files[0], 
                };
            } else {
                return {
                    ...prevState,
                    [name]: value,
                };
            }
        });

        setErrors(prevState => ({
            ...prevState,
            [name]: '',
        }));
    };


    const validateForm = () => {
        let isValid = true;
        let errors = {};

        templateData.forEach(template => {
            if (!formData[template.label]) {
                isValid = false;
                errors[template.label] = `${template.label} is required`;
            }
        });

        setErrors(errors);
        return { isValid, errors };
    };


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const { isValid, errors } = validateForm();

            if (!isValid) {
                console.error("Validation errors:", errors);
                swal("Validation Error", "Please fill all required fields", "error");
                return;
            }

            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                formDataToSend.append(key, formData[key]);
            });
            formDataToSend.append('templateName', templateName);
            let create = await Authapi.addlettersFieldata(formDataToSend, templateName);
            if (create && create.status === true) {
                swal("Success", "Offer letter added successfully", "success")
                    .then(() => {
                        props.history.push("/Documentslist");
                    });
            } else {
                console.error("Error creating entry:", create.message);
                swal("Error", create.message || "An error occurred while creating the offer letter", "error");
            }
        } catch (error) {
            console.error("Error during submission:", error);
            swal("Error", "An unexpected error occurred", "error");
        }
    };

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="Documentslist"
                                    primaryPageLink="/Documentslist"
                                    isSecondaryPage={true}
                                    secondaryPageName="Offer Latter" />
                            </CardContent>
                        </Card>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h2" style={{ paddingLeft: 5 }}>Add {templateName}</Typography>
                                        <form id="create-course-form" onSubmit={handleSubmit}>
                                            <Grid container spacing={5}>
                                                {templateData.map((template, index) => (
                                                    <Grid item xs={12} md={6} key={index}>
                                                        {template.type === 'checkbox' ? (
                                                            <>
                                                                <Typography variant="h6" style={{ textAlign: "justify", marginBottom: '8px' }}>
                                                                    {template.label}
                                                                </Typography>
                                                                <div style={{ textAlign: "justify" }}>
                                                                    {template.options.map((option, idx) => (
                                                                        <FormControlLabel
                                                                            key={idx}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={(formData[template.label] || '').split(', ').includes(option)}
                                                                                    onChange={handleInputChange}
                                                                                    name={template.label}
                                                                                    value={option}
                                                                                />
                                                                            }
                                                                            label={option}
                                                                            style={{ marginRight: '16px' }}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </>
                                                        ) : template.type === 'radio' ? (
                                                            <>
                                                                <Typography variant="h6" style={{ textAlign: "justify", marginBottom: '8px' }}>
                                                                    {template.label}
                                                                </Typography>
                                                                <RadioGroup
                                                                    row
                                                                    name={template.label}
                                                                    value={formData[template.label] || ''}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    {template.options.map((option, idx) => (
                                                                        <FormControlLabel
                                                                            key={idx}
                                                                            value={option}
                                                                            control={<Radio />}
                                                                            label={option}
                                                                            style={{ marginRight: '16px' }}
                                                                        />
                                                                    ))}
                                                                </RadioGroup>
                                                            </>
                                                        ) : template.type === 'textarea' ? (
                                                            <TextField
                                                                fullWidth
                                                                id={`template-${index}`}
                                                                name={template.label}
                                                                label={template.label}
                                                                variant="outlined"
                                                                multiline
                                                                rows={4} 
                                                                value={formData[template.label] || ''}
                                                                onChange={handleInputChange}
                                                                error={!!errors[template.label]}
                                                                helperText={errors[template.label]}
                                                                style={{ marginBottom: '16px' }}
                                                            />
                                                        ) : (
                                                            <TextField
                                                                fullWidth
                                                                id={`template-${index}`}
                                                                name={template.label}
                                                                label={template.label}
                                                                variant="outlined"
                                                                type={template.type}
                                                                value={template.type === 'file' ? undefined : formData[template.label] || ''}
                                                                onChange={handleInputChange}
                                                                error={!!errors[template.label]}
                                                                helperText={errors[template.label]}
                                                                style={{ marginBottom: '16px' }}
                                                                InputLabelProps={['date', 'file'].includes(template.type) ? { shrink: true } : {}}
                                                                inputProps={template.type === 'file' ? { accept: 'image/*' } : {}}
                                                            />
                                                        )}
                                                    </Grid>
                                                ))}
                                            </Grid>

                                            <Grid container spacing={3}>
                                                <Grid item xs={4} style={{ display: 'flex' }}>
                                                    <Button
                                                        type="submit"
                                                        style={{ marginTop: "10px" }}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Save
                                                    </Button>

                                                    <Button type="button" style={{ marginTop: "10px" }} onClick={(e) => { e.preventDefault(); props.history.push('/Documentslist') }}>Cancel</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                } />
            <Footer />
        </div>
    );
};

export default OfferLatterAdd;
