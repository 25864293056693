import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Authapi from "../../Services/Authapi";
import "./tablefilter.css";
import ls from "local-storage";
import { DataGrid, GridToolbar, GridPagination } from "@mui/x-data-grid";
import { Link, useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { MdAddBox } from "react-icons/md";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
// import Switch from "react-switch";


const isAdminOnLeaveTicketPage = ls("roles") === "Super Admin";

const Tableforfilter = () => {
  const [totalDurationTime, setTotalDurationTime] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [leaveTickets, setLeaveTickets] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const history = useHistory();

  const handleClearSearch = () => {
    setSearchQuery("");
    setTotalDurationTime(originalData);
    setSuggestions([]);
  };

  useEffect(() => {
    let today = new Date();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    if (isAdminOnLeaveTicketPage) {
      Authapi.AllgetAttandance(month, year).then((data) => {
        const formattedData = data.data.map((item, index) => ({
          id: index,
          name: item.name,
          firstName: item.firstName,
          date: item.date,
          hours: item.hours,
          attendance: item.attendance,
          dayType: item.dayType,
          userId: item.userId,
        }));
        setTotalDurationTime(formattedData);
        setOriginalData(formattedData);
      });
    } else {
      Authapi.getAttandance(month, year).then((data) => {
        const formattedData = data.data.map((item, index) => ({
          id: index,
          name: item.name,
          firstName: item.firstName,
          date: item.date,
          hours: item.hours,
          attendance: item.attendance,
          dayType: item.dayType,
          userId: item.userId,
        }));
        setTotalDurationTime(formattedData);
        setOriginalData(formattedData);
      });
    }

    Authapi.getTickets1().then((data) => {
      setLeaveTickets(data.data);
    });
  }, []);

  const handleMonthChange = async (e) => {
    let select = e.target.value;
    let ds = new Date(select);
    let month = ds.getMonth() + 1;
    let year = ds.getFullYear();
    if (isAdminOnLeaveTicketPage) {
      Authapi.AllgetAttandance(month, year).then((data) =>
        setTotalDurationTime(
          data.data.map((item, index) => ({
            id: index,
            name: item.name,
            firstName: item.firstName,
            date: item.date,
            hours: item.hours,
            attendance: item.attendance,
            dayType: item.dayType,
            userId: item.userId,
          }))
        )
      );
    } else {
      Authapi.getAttandance(month, year).then((data) =>
        setTotalDurationTime(
          data.data.map((item, index) => ({
            id: index,
            name: item.name,
            firstName: item.firstName,
            date: item.date,
            hours: item.hours,
            attendance: item.attendance,
            dayType: item.dayType,
            userId: item.userId,
          }))
        )
      );
    }

    Authapi.getLeaveTickets().then((data) => {
      setLeaveTickets(data.data);
    });
  };

  const handleSearch = () => {
    const filteredData = totalDurationTime.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.firstName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setTotalDurationTime(filteredData);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion.firstName);
    setSuggestions([]);
    handleSearch();
  };

  const handleChange = (e) => {
    const searchQuery = e.target.value;
    const suggestions = originalData.filter((item) => {
      return (
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.firstName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setSearchQuery(searchQuery);
    setSuggestions(suggestions);
  };

  var month = new Array();
  month[0] = "01";
  month[1] = "02";
  month[2] = "03";
  month[3] = "04";
  month[4] = "05";
  month[5] = "06";
  month[6] = "07";
  month[7] = "08";
  month[8] = "09";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  var d = new Date();
  var dateString = d.getFullYear() + "-" + month[d.getMonth()];

  const renderDayType = (params) => {
    const authUserId = ls("user").id;

    const leaveDateMatch = leaveTickets.some((ticket) => {
      const [startDate, endDate] = ticket.date_range.split(" to ");
      const start = new Date(startDate.split('-').reverse().join('-'));
      const end = new Date(endDate.split('-').reverse().join('-'));
      const currentDate = new Date(params.row.date.split('-').reverse().join('-'));

      const isMatch = currentDate >= start && currentDate <= end && ticket.user_id === authUserId;
      return isMatch;
    });

    if (params.value === "Left Early" && params.row.userId === authUserId && !leaveDateMatch) {
      const leaveDate = params.row.date;
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.value}
          <Tooltip title="Add Leave">
            <Link
              to={`/tickets/add?date=${leaveDate}`}
              style={{ textDecoration: "none", color: "#1b6e95", fontWeight: "bold", marginLeft: "5px", marginTop: "8px" }}
            >
              <MdAddBox size={24} />
            </Link>
          </Tooltip>
        </div>
      );
    }
    return params.value;
  };

  const columns = [
    { field: "name", headerName: "Employee ID", flex: 1, headerClassName: 'header-red' },
    { field: "firstName", headerName: "Name", flex: 1, headerClassName: 'header-red' },
    { field: "date", headerName: "Date", flex: 1, headerClassName: 'header-red' },
    { field: "hours", headerName: "Hours / Min", flex: 1, headerClassName: 'header-red' },
    { field: "attendance", headerName: "Attendance", flex: 1, headerClassName: 'header-red' },
    { field: "dayType", headerName: "Day-Type", flex: 1, headerClassName: 'header-red', renderCell: renderDayType },
  ];

  // Ensure pageSize is in options
  const pageSizeOptions = [5, 10, 25];
  if (!pageSizeOptions.includes(pageSize)) {
    pageSizeOptions.push(pageSize);
  }
  pageSizeOptions.push({ value: totalDurationTime.length, label: 'All' });



  // const toggleRole = async () => {
  //   try {
  //     const response = await Authapi.toggleRole(ls("user").id);
  //     if (response && response.status === true) {
  //       getAuth();
  //       handleRefresh1()
  //     }
  //   } catch (error) {
  //     alert('An error occurred');
  //   }
  // };

  // const is_role_toggled = ls("user").is_role_toggled;
  // const role = ls("roles")

  // const getAuth = async () => {
  //   let checkLogin = await Authapi.checkAuth();
  //   if (checkLogin && checkLogin.status !== false) {
  //     // Update local storage with new data
  //     // ls.set("roles", checkLogin.data.roles || false);
  //     // ls.set("authToken", checkLogin.data.authToken || false);
  //     ls.set("user", checkLogin.data.user || false);
  //     window.location.reload();
  //     // this.setAutUser({ authUser: checkLogin.data });
  //   } else {
  //     ls.set("roles", false);
  //     ls.set("authToken", false);
  //     ls.set("user", false);
  //     this.setAutUser({
  //       authUser: false,
  //       authToken: false,
  //       roles: false,
  //       user: false,
  //     });
  //   }
  // }



  // const handleRefresh1 = async () => {
  //   try {
  //     let formData = {
  //       user_id: ls("user").id
  //     };
  //     const newToken = await Authapi.refreshToken(formData);
  //     localStorage.setItem('authToken', newToken.access_token);
  //     localStorage.setItem('roles', newToken.roles);
  //     localStorage.setItem('tokenRefreshed', 'true');

  //     // window.location.reload();
  //   } catch (error) {
  //     console.error('Error refreshing token:', error);
  //   }
  // };

  return (
    <>
      {/* {is_role_toggled === 1 || role === "Super Admin" ? (
        <button onClick={toggleRole}>
          Toggle Role
        </button>
      ) : null} */}

      {/* {is_role_toggled === 1 || role === "Super Admin" ? (
        <label>
          <Switch onChange={toggleRole} checked={is_role_toggled} />
        </label>
      ) : null} */}

      <div>
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
          }}
        >


          <TextField
            id="datetime-local"
            label="Select Month/Year"
            views={["year", "month"]}
            variant="outlined"
            defaultValue={dateString}
            type="Month"
            onChange={handleMonthChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: "2020-01", max: dateString }}
          />

          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleChange}
            style={{
              position: "absolute",
              right: "46px",
              marginTop: "21px"
            }}
            variant="outlined"
            InputLabelProps={{
              shrink: searchQuery !== "",
              style: {
                color: '#1b6e95',
                transform: searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 9px) scale(1)',
                transition: 'transform 0.2s ease-in-out'
              }
            }}
            InputProps={{
              style: {
                backgroundColor: 'lightgray',
                borderRadius: '4px',
                height: "40px"
              },
              // endAdornment: (
              //   <InputAdornment position="end">
              //     {searchQuery && (
              //       <IconButton onClick={handleClearSearch}>
              //         <ClearIcon />
              //       </IconButton>
              //     )}
              //     <IconButton onClick={handleSearch}>
              //       <SearchIcon />
              //     </IconButton>
              //   </InputAdornment>
              // )
              endAdornment: (
                <InputAdornment position="end">
                  {searchQuery && (
                    <ClearIcon onClick={handleClearSearch} style={{ cursor: 'pointer' }} />
                  )}
                  <SearchIcon onClick={handleSearch} style={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),

            }}
          />
        </div>

        {searchQuery && suggestions.length > 0 && (
          <Paper
            style={{
              position: 'absolute',
              right: '114px',
              marginTop: '-5px',
              zIndex: 1,
              width: "207px",
              maxHeight: '200px',
              overflowY: 'auto',
              border: '1px solid #ccc',
              borderRadius: '4px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
            }}>
            {[...new Set(suggestions.map(suggestion => suggestion.firstName))].map((firstName, index) => (
              <MenuItem
                key={index}
                onClick={() => handleSuggestionClick({ firstName })}
                style={{
                  padding: '10px 20px',
                  cursor: 'pointer'
                }}
              >
                {firstName}
              </MenuItem>
            ))}
          </Paper>
        )}

        <>
          {/* <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          columns={columns}
          rows={totalDurationTime}
          pageSize={pageSize}
          components={{
            Toolbar: GridToolbar,
            Pagination: GridPagination,
            NoRowsOverlay: () => (
              <div style={{ padding: "16px", textAlign: "center" }}>
                No data found
              </div>
            ),
          }}
          rowsPerPageOptions={[5, 10, 25, { value: totalDurationTime.length, label: 'All' }]}
          onPageChange={(newPage) => console.log(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      </Box> */}

          <Box sx={{ height: 400, width: 1 }}>
            <DataGrid
              columns={columns}
              rows={totalDurationTime}
              pageSize={pageSize}
              rowsPerPageOptions={pageSizeOptions}
              components={{
                Toolbar: GridToolbar,
                Pagination: GridPagination,
                NoRowsOverlay: () => (
                  <div style={{ padding: "16px", textAlign: "center" }}>
                    No data found
                  </div>
                ),
              }}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </Box>

        </>
      </div>
    </>
  );
};

export default Tableforfilter;
