// / eslint-disable react-hooks/exhaustive - deps /
import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import swal from "sweetalert";
import AuthApi from "../../Services/Authapi";
import TextField from '@material-ui/core/TextField';
// import draftToHtml from 'draftjs-to-html';
// import { EditorState } from 'draft-js';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AuthCheckComponent from '../../Layouts/Token';

const iniLatter = {
    title: '',
    texteditor: '',
    templates: [],
    // event_Id: 0,
}

const Edittemplate = (props) => {
    const classes = props;
    const [doc, setDoc] = useState(iniLatter);
    // const [editorState, setEditorState] = React.useState(
    //     EditorState.createEmpty());
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }
    }, []);

    useEffect(() => {
        getEventData();
    }, []);

    const getEventData = async () => {
        let Id = props.history.location.pathname.split('/')[props.history.location.pathname.split('/').length - 1];
        let upcomingEvent = await AuthApi.Templategeedit(Id);
        const event_Id = Id;
        let template_name = (upcomingEvent.status === true && upcomingEvent.data && upcomingEvent.data.template_name && (upcomingEvent.data.template_name !== null || upcomingEvent.data.template_name !== false)) ? upcomingEvent.data.template_name : null;
        let letters = (upcomingEvent.status === true && upcomingEvent.data && upcomingEvent.data.letters && (upcomingEvent.data.letters !== null || upcomingEvent.data.letters !== false)) ? upcomingEvent.data.letters : null;
        let templates = (upcomingEvent.status === true && upcomingEvent.data && upcomingEvent.data.templates) ? upcomingEvent.data.templates : [];
        setDoc({
            title: template_name,
            texteditor: letters,
            templates: templates,
            event_Id: event_Id,
        });
    };

    useEffect(() => { setTimeout(() => getEventData(), 500) }, []);

    const handleChange = (e, editorData) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setDoc({ ...doc, [name]: value });
        } else if (editorData !== undefined) {
            setDoc({ ...doc, texteditor: editorData });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(doc));
        setIsSubmitting(true);
        // console.log("Submitting document:", doc); // Debugging: Check the state before submission
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[a-zA-Z]/;

        if (!values.title) {
            errors.title = "Cannot be blank";
        } else if (!regex.test(values.title)) {
            errors.title = "Invalid title format";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            editUser1();
        }
    }, [formErrors]);

    const editUser1 = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;"
                }
            }
        });
        try {
            // console.log("Updating with document:", doc); // Debugging: Check the state before API call
            let status = await AuthApi.Templateupdate(doc, doc.event_Id);
            swal.close();
            if (status && status.status === true) {
                props.history.push("/latter");
            }
        } catch (error) {
            console.error("Error updating latter:", error);
        }
    };

    const addTemplateField = () => {
        setDoc({ ...doc, templates: [...doc.templates, { label: '', type: '', options: [] }] });
    };

    const handleTemplateChange = (index, field, value) => {
        const newTemplates = [...doc.templates];
        newTemplates[index][field] = value;
        setDoc({ ...doc, templates: newTemplates });
    };

    const handleOptionChange = (templateIndex, optionIndex, value) => {
        const newTemplates = [...doc.templates];
        newTemplates[templateIndex].options[optionIndex] = value;
        setDoc({ ...doc, templates: newTemplates });
    };

    const addOption = (index) => {
        const newTemplates = [...doc.templates];
        if (!newTemplates[index].options) {
            newTemplates[index].options = [];
        }
        newTemplates[index].options.push('');
        setDoc({ ...doc, templates: newTemplates });
    };

    const removeOption = (templateIndex, optionIndex) => {
        const newTemplates = [...doc.templates];
        newTemplates[templateIndex].options.splice(optionIndex, 1);
        setDoc({ ...doc, templates: newTemplates });
    };

    const removeTemplateField = (index) => {
        const newTemplates = doc.templates.filter((_, i) => i !== index);
        setDoc({ ...doc, templates: newTemplates });
    };

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="User Template"
                                    primaryPageLink="/latter"
                                    isSecondaryPage={false}
                                    secondaryPageName="" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2" style={{ paddingTop: 0, paddingLeft: 0, paddingBottom: 0 }}>Generate Template</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit}>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    {/* {console.log(doc.title)} */}
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        name="title"
                                                        label="Title"
                                                        variant="outlined"
                                                        error={formErrors.title && true}
                                                        value={doc.title}
                                                        onChange={handleChange}
                                                        className={formErrors.title ? "input-error" : ""}
                                                    />
                                                    {formErrors.title && (
                                                        <span className="error">
                                                            {formErrors.title}
                                                        </span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={doc.texteditor}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            handleChange(null, data);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {doc.templates.map((template, index) => (
                                                <Grid container className={classes.root} spacing={5} key={index}>
                                                    <Grid item xs={5}>
                                                        <TextField
                                                            fullWidth
                                                            id={`template-label-${index}`}
                                                            label="Template Label"
                                                            variant="outlined"
                                                            value={template.label}
                                                            onChange={(e) => handleTemplateChange(index, 'label', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField
                                                            fullWidth
                                                            id={`template-type-${index}`}
                                                            label="Template Type"
                                                            variant="outlined"
                                                            value={template.type}
                                                            onChange={(e) => handleTemplateChange(index, 'type', e.target.value)}
                                                            select
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                        >
                                                            <option value="text">Text</option>
                                                            <option value="date">Date</option>
                                                            {/* <option value="file">file</option> */}
                                                            <option value="email">Email</option>
                                                            <option value="month">Month</option>
                                                            <option value="number">Number</option>
                                                            <option value="password">Password</option>
                                                            <option value="time">Time</option>
                                                            <option value="textarea">Textarea </option>
                                                            <option value="color">Color</option>
                                                            <option value="hidden">Hidden</option>
                                                            <option value="submit">Submit</option>
                                                            <option value="radio">Radio</option>
                                                            <option value="checkbox">Checkbox</option>
                                                            <option value="file">Image</option>
                                                        </TextField>
                                                    </Grid>
                                                    {['radio', 'checkbox'].includes(template.type) && (
                                                        <Grid item xs={10}>
                                                            {template.options && template.options.map((option, optIndex) => (
                                                                <Grid container spacing={2} key={optIndex}>
                                                                    <Grid item xs={8}>
                                                                        <TextField
                                                                            fullWidth
                                                                            id={`option-${index}-${optIndex}`}
                                                                            label={`Option ${optIndex + 1}`}
                                                                            value={option}
                                                                            onChange={(e) => handleOptionChange(index, optIndex, e.target.value)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => removeOption(index, optIndex)}
                                                                            variant="contained"
                                                                            style={{
                                                                                color: "#fff",
                                                                                backgroundColor: "#d12c2c",
                                                                                marginTop: "10px"
                                                                            }}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                            <Button
                                                                onClick={() => addOption(index)}
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ marginTop: "10px" }}
                                                            >
                                                                Add Option
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={2}>
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                color: "#fff",
                                                                backgroundColor: "#d12c2c",
                                                                marginTop: "10px"
                                                            }}
                                                            onClick={() => removeTemplateField(index)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                                <Button onClick={addTemplateField} variant="contained" color="primary">
                                                    Add Input Field
                                                </Button>
                                            </Grid>

                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4}>
                                                    <Button type="submit" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} variant="contained" color="primary">
                                                        Save
                                                    </Button>
                                                    <Button type="button" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} onClick={(e) => { e.preventDefault(); props.history.push('/latter') }}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                } />
            <Footer />
        </div>
    );
};

export default Edittemplate;    