import React, { Component } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import AuthApi from "../../Services/Authapi";
import TableComponent from "../../Layouts/TableComponent";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import AuthCheckComponent from '../../Layouts/Token';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import DialogActions from '@mui/material/DialogActions';
import ls from "local-storage";
import "./Check.css"

import {
  Dialog,
  DialogTitle,
  DialogContent,
  // Tooltip,
  MenuItem,
  Paper,
  Select,
  Button,
  Checkbox,
  // IconButton,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
// import FontAwesomeIconComponent from "../../Layouts/FontAwesomeIconComponent";

class Check extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getData: [],
      count: 0,
      pageStatus: false,
      isApproved: 0,
      searchQuery: "",
      suggestions: [],
      selectedTimeEntry: null,
      showModalFromNotification: false,
      tableTh: [
        {
          id: "select",
          numeric: false,
          disablePadding: true,
          label: (
            <div className="wrap-check-20">
              <label>
                <input
                  type="checkbox"
                  checked={this.isAllSelected()}
                  onChange={this.handleSelectAllEntries}
                />
                <span className="checkbox"></span>
              </label>
            </div>
          ),
          sortable: false,
        },
        {
          id: "user_id",
          numeric: false,
          disablePadding: true,
          label: "Employee ID",
          sortable: true,
        },
        {
          id: "Name",
          numeric: false,
          disablePadding: true,
          label: "Name",
          sortable: true,
        },
        {
          id: "date",
          numeric: false,
          disablePadding: true,
          label: "Date",
          sortable: true,
        },
        {
          id: "intime",
          numeric: false,
          disablePadding: true,
          label: "In Time",
          sortable: true,
        },
        {
          id: "outtime",
          numeric: false,
          disablePadding: true,
          label: "Out Time",
          sortable: true,
        },
        {
          id: "duration",
          numeric: false,
          disablePadding: true,
          label: "Durations",
          sortable: true,
        },
        {
          id: "status",
          numeric: false,
          disablePadding: true,
          label: "Status",
          sortable: false,
        },
        {
          id: "action",
          numeric: false,
          disablePadding: true,
          label: "Action",
          sortable: false,
          // style: { width: "100px" },
        },
        // {
        //   id: "action",
        //   numeric: false,
        //   disablePadding: true,
        //   label: "",
        //   sortable: false,
        //   // style: { width: "100px" },
        // },
      ],
      bulkAction: '',
      selectedEntries: [],
      isAdmin: false,
      editDialogOpen: false,
      editEntry: null,
      formErrors: {},
    };
    this.getUserData = this.getUserData.bind(this);
    this.decline = this.decline.bind(this);
    this.accept = this.accept.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
    this.handleBulkActionChange = this.handleBulkActionChange.bind(this);
    this.handleBulkActionSubmit = this.handleBulkActionSubmit.bind(this);
    this.handleEntrySelect = this.handleEntrySelect.bind(this);
    this.viewDetails = this.viewDetails.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.handleEditDialogClose = this.handleEditDialogClose.bind(this);
  }

  componentDidMount() {
    if (this.props && this.props.authToken === false) {
      // this.props.history.push("/login");
    }
    this.getUserData();

    const fromNotificationPanel = sessionStorage.getItem('fromNotificationPanel');
    if (this.props.location.state && this.props.location.state.notificationData && fromNotificationPanel) {
      const { dataId } = this.props.location.state.notificationData;
      this.fetchTimeEntryDetails(dataId);
      this.setState({ showModalFromNotification: true });
      sessionStorage.removeItem('fromNotificationPanel');
    }

    const fromDashboard = sessionStorage.getItem('fromDashboard');
    if (this.props.location.state && this.props.location.state.timeEntryDetails && fromDashboard) {
      const { timeEntryDetails } = this.props.location.state;
      this.setState({ selectedTimeEntry: timeEntryDetails, showModalFromNotification: true });
      sessionStorage.removeItem('fromDashboard');
    }
  }
  componentWillReceiveProps(props) {
    if (props && props.authToken === false) {
      // props.history.push("/login");
    }
  }

  handleSelectAllEntries = () => {
    this.setState((prevState) => {
      const allEntryIds = prevState.getData.map((entry) => entry.id);
      const allSelected = prevState.selectedEntries.length !== allEntryIds.length;
      const selectedEntries = allSelected ? allEntryIds : [];
      return { selectedEntries };
    });
  };

  isAllSelected = () => {
    const { getData = [], selectedEntries = [] } = this.state || {};
    return getData.length > 0 && getData.every(entry => selectedEntries.includes(entry.id));
  };


  getUserData = async (e) => {
    const isAdmin = ls("roles") === "Super Admin";
    let userData;
    if (isAdmin) {
      // alert("sfsdfsdfsdf")
      userData = await AuthApi.chekUserData();
    } else {
      userData = await AuthApi.TimeEntrshowuser(ls("user").id);
    }

    if (userData && userData.status === true) {
      this.setState({
        getData: userData.data.map(entry => ({
          ...entry,
          statusLabel: this.getStatusLabel(entry.is_approved)
        })),
        count: userData.data.length,
        isAdmin: isAdmin,
      });
    }
  };

  getStatusLabel = (isApproved) => {
    switch (isApproved) {
      case 0:
        return <span style={{ color: 'green' }}>Approved</span>;
      case 1:
        return <span style={{ color: 'orange' }}>Pending</span>;
      case 2:
        return <span style={{ color: 'red' }}>Rejected</span>;
      default:
        return <span>Unknown</span>;
    }
  };


  fetchTimeEntryDetails = async (dataId) => {
    try {
      const userData = await AuthApi.chekUserData();
      if (userData && userData.status === true) {
        const timeEntry = userData.data.find((entry) => entry.id === dataId);
        if (timeEntry) {
          this.setState({
            selectedTimeEntry: timeEntry,
            showModalFromNotification: true,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching time entry details:", error);
    }
  };

  accept = async (id) => {
    const user = ls("user");
    const userName = `${user.firstName} ${user.lastName}`;

    const ticketUpdateData = {
      is_approved: "0",
      userName: userName,
    };

    swal({
      title: "Are you sure?",
      text: "Do you want to approve this entry?",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
    }).then(async (confirm) => {
      if (confirm) {
        let user = await AuthApi.approveTime(ticketUpdateData, id);
        if (user && user.status == true) {
          this.setState({
            pageStatus: true,
          });
          this.getUserData();
          setTimeout(() => this.setState({ pageStatus: false }), 500);
          swal("Done!", "You have approved the entry!", "success");
        } else {
          swal("Error!", "There was an error approving the entry.", "error");
        }
      }
    });
  };

  decline = async (id) => {
    const user = ls("user");
    const userName = `${user.firstName} ${user.lastName}`;

    const ticketUpdateData = {
      is_approved: "2",
      userName: userName,
    };
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this entry?",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
    }).then(async (confirm) => {
      if (confirm) {
        let userCheck = await AuthApi.approveTime(ticketUpdateData, id);
        if (userCheck && userCheck.status === true) {
          this.setState({
            pageStatus: true,
          });
          this.getUserData();
          setTimeout(() => this.setState({ pageStatus: false }), 500);
          swal("Done!", "You have rejected the entry!", "success");
        } else {
          swal("Error!", "There was an error rejecting the entry.", "error");
        }
      }
    });
  }



  handleChange(e) {
    const searchQuery = e.target.value;
    const suggestions = this.state.getData.filter((item) => {
      return (
        item.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    this.setState({ searchQuery, suggestions });
  }

  handleCloseModal = () => {
    this.setState({ showModalFromNotification: false, selectedTimeEntry: null });
  };

  handleSearch = () => {
    // Trigger search functionality
    const filterData = this.state.getData.filter((item) => {
      return (
        item.date.toLowerCase().includes(this.state.searchQuery.toLowerCase()) ||
        item.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      );
    });
    this.setState({ filterData });
  };

  clearSearch = () => {
    this.setState({ searchQuery: "", suggestions: [] }, this.handleSearch);
  };

  handleSuggestionClick = (suggestion) => {
    this.setState({ searchQuery: suggestion.name, suggestions: [] }, this.handleSearch);
  };

  handleBulkActionChange(event) {
    this.setState({ bulkAction: event.target.value });
  }

  handleBulkActionSubmit() {
    const { bulkAction, selectedEntries } = this.state;
    if (bulkAction && selectedEntries.length > 0) {
      swal({
        title: "Are you sure?",
        text: `You are about to ${bulkAction} ${selectedEntries.length} entries.`,
        icon: "warning",
        buttons: ["Cancel", "Yes"],
      }).then(async (confirm) => {
        if (confirm) {
          // Implement bulk action logic here
          const user = ls("user");
          const userName = `${user.firstName} ${user.lastName}`;
          const is_approved = bulkAction === 'approve' ? "0" : "2";

          for (let id of selectedEntries) {
            await AuthApi.approveTime({ is_approved, userName }, id);
          }

          this.setState({
            pageStatus: true,
            selectedEntries: [],
            bulkAction: '',
          });
          this.getUserData();
          setTimeout(() => this.setState({ pageStatus: false }), 500);
        }
      });
    }
  }

  handleEntrySelect(id) {
    this.setState(prevState => ({
      selectedEntries: prevState.selectedEntries.includes(id)
        ? prevState.selectedEntries.filter(entryId => entryId !== id)
        : [...prevState.selectedEntries, id]
    }));
  }

  viewDetails(id) {
    const selectedTimeEntry = this.state.getData.find(entry => entry.id === id);
    this.setState({ selectedTimeEntry, showModalFromNotification: true });
  }

  handleEditClick(entry) {
    this.setState({ editDialogOpen: true, editEntry: { ...entry } });
  }

  handleEditChange(e) {
    const { name, value } = e.target;
    this.setState(prevState => ({
      editEntry: {
        ...prevState.editEntry,
        [name]: name === 'date' ? this.formatDateToDisplay(value) : value,
      }
    }));
  }

  async handleEditSubmit() {
    const { editEntry } = this.state;
    const user = ls("user");
    try {
      const formattedEntry = {
        user_id: editEntry.user_id,
        date: this.formatDateToInput(editEntry.date),
        in_time: editEntry.in_time,
        out_time: editEntry.out_time,
        duration: editEntry.duration,
        is_approved: editEntry.is_approved,
      };

      const response = await AuthApi.editTime(user.id, editEntry.id, formattedEntry);

      if (response && response.status === true) {
        this.setState({ editDialogOpen: false, editEntry: null });
        this.getUserData();
        swal("Success!", "Time entry updated successfully.", "success");
      } else {
        swal("Error!", "Failed to update time entry.", "error");
      }
    } catch (error) {
      console.error("Error updating time entry:", error);
      swal("Error!", "An error occurred while updating the time entry.", "error");
    }
  }

  handleEditDialogClose() {
    this.setState({ editDialogOpen: false, editEntry: null });
  }

  formatDateToInput(date) {
    const [day, month, year] = date.split('-');
    return `${year}-${month}-${day}`;
  }

  formatDateToDisplay(date) {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  }

  handleDelete = async (id) => {
    try {
      const response = await AuthApi.deletetimeentry(id);
      if (response && response.status === true) {
        this.getUserData();
        swal("Success!", "Time entry deleted successfully.", "success");
      } else {
        swal("Error!", "Failed to delete time entry.", "error");
      }
    } catch (error) {
      console.error("Error deleting time entry:", error);
      swal("Error!", "An error occurred while deleting the time entry.", "error");
    }
  };

  render() {
    const classes = this.props;
    const filterData = this.state.filterData || this.state.getData;
    const permissions = (this.props.authUser && Array.isArray(this.props.authUser.userAccess)) ? this.props.authUser.userAccess : [];
    const { editDialogOpen, editEntry, formErrors, selectedTimeEntry, showModalFromNotification, isAdmin } = this.state;

    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + (d.getDate() - 1),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    var ds = [year, month, day].join('-');

    return (
      <div className="check-container">
        <AuthCheckComponent authToken={this.props.authToken} />
        <Header
          {...this.props}
          authUser={this.props.authUser}
          setAutUser={this.props.setAutUser}
          component={
            <div style={{ marginBottom: "7%" }}>
              <Card className={classes.root} style={{ marginBottom: "3%" }}>
                <CardContent>
                  <Breadcrumb
                    {...this.props}
                    primaryPageName="Check User"
                    primaryPageLink="/check"
                    isSecondaryPage={false}
                    secondaryPageName=""
                  />
                </CardContent>
              </Card>
              <TextField
                label="Search"
                value={this.state.searchQuery}
                onChange={this.handleChange}
                style={{
                  position: "absolute",
                  right: "128px",
                  marginTop: "21px"
                }}
                variant="outlined"
                InputLabelProps={{
                  shrink: this.state.searchQuery !== "",
                  style: {
                    color: '#1b6e95',
                    transform: this.state.searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)',
                    transition: 'transform 0.2s ease-in-out'
                  }
                }}
                InputProps={{
                  style: {
                    backgroundColor: 'lightgray',
                    borderRadius: '4px',
                    height: "40px"
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {this.state.searchQuery && (
                        <ClearIcon onClick={this.clearSearch} style={{ cursor: 'pointer' }} />
                      )}
                      <SearchIcon onClick={this.handleSearch} style={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  ),
                }}
              />

              {this.state.searchQuery && this.state.suggestions.length > 0 && (
                <Paper style={{ position: 'absolute', right: '191px', marginTop: '62px', zIndex: 1, width: "213px", maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', borderRadius: '4px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                  {[...new Set(this.state.suggestions.map(suggestion => suggestion.name))].map((name, index) => (
                    <MenuItem key={index} onClick={() => this.handleSuggestionClick({ name })} style={{ padding: '10px 20px', cursor: 'pointer' }}>
                      {name}
                    </MenuItem>
                  ))}
                </Paper>
              )}

              <TableComponent
                {...this.props}
                tableTh={this.state.tableTh.map(th => {
                  if (th.id === "select") {
                    return {
                      ...th,
                      label: (
                        <div className="wrap-check-20">
                          <label>
                            <input
                              type="checkbox"
                              checked={this.isAllSelected()}
                              onChange={this.handleSelectAllEntries}
                            />
                            <span className="checkbox"></span>
                          </label>
                        </div>
                      )
                    };
                  }
                  return th;
                })}
                tableData={filterData.filter(entry => entry !== undefined && entry !== null).map(entry => ({
                  ...entry,
                  select: (
                    <div className="wrap-check-20">
                      <label>
                        <input
                          type="checkbox"
                          checked={this.state.selectedEntries.includes(entry.id)}
                          onChange={() => this.handleEntrySelect(entry.id)}
                        />
                        <span className="checkbox"></span>
                      </label>
                    </div>
                  ),
                  status: entry.statusLabel,
                }))}
                tableCount={filterData.length}
                tablePagestatus={this.state.pageStatus}
                colNameToShow={["select", "name", "Name", "date", "in_time", "out_time", "duration", "status"]}
                openPopUp={false}
                actionBtns={[
                  permissions.includes("Timeentry.Approve") && 'approve111',
                  permissions.includes("Timeentry.Reject") && 'reject11',
                  permissions.includes("Timeentry.Viewdetails") && 'viewdetails',
                  permissions.includes("Timeentry.Edit") && 'edit',
                  permissions.includes("Timeentry.Deletetimeentry") && 'deletetimeentry',
                ]}
                modelName={"Check"}
                addRoute={'/time'}
                openPopUpUpdate={false}
                viewDetails={this.viewDetails}
                handleEditClick={this.handleEditClick}
                handleDelete={this.handleDelete}
                acceptRow={this.accept}
                rejectRow={this.decline}
              />
              {(permissions.includes("Timeentry.Bulkapprove") || permissions.includes("Timeentry.Bulkreject")) && (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: "-49px" }}>
                  <Select
                    value={this.state.bulkAction}
                    onChange={this.handleBulkActionChange}
                    displayEmpty
                    style={{ marginRight: '10px', minWidth: '120px' }}
                  >
                    <MenuItem value="" disabled>Bulk Action</MenuItem>
                    {permissions.includes("Timeentry.Bulkapprove") && (
                      <MenuItem value="approve">Approve</MenuItem>
                    )}
                    {permissions.includes("Timeentry.Bulkreject") && (
                      <MenuItem value="reject">Reject</MenuItem>
                    )}
                  </Select>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleBulkActionSubmit}
                    disabled={!this.state.bulkAction || this.state.selectedEntries.length === 0}
                  >
                    Go
                  </Button>
                </div>
              )}
            </div>
          }
        />
        <Footer {...this.props} />

        {showModalFromNotification && selectedTimeEntry && (
          <Dialog
            open={showModalFromNotification}
            onClose={this.handleCloseModal}
            maxWidth="xs"
            fullWidth
            PaperProps={{
              style: {
                padding: "10px",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                width: "350px",
              },
            }}
          >
            <div style={{ position: "relative" }}>
              <DialogTitle
                style={{
                  textAlign: "center",
                  padding: "5px 0",
                  backgroundColor: "#1b6e95",
                  color: "#fff",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                Time Entry Details
              </DialogTitle>
              <CloseIcon
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                  color: "#fff",
                  backgroundColor: "#f44336",
                  borderRadius: "50%",
                  padding: "2px",
                }}
                onClick={this.handleCloseModal}
              />
              <DialogContent>
                <div style={{ padding: "10px 20px" }}>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Employee ID</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.name}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Name</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.Name}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Date</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.date}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>In Time</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.in_time}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Out Time</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.out_time}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Duration</b></td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedTimeEntry.duration}</td>
                      </tr>
                    </tbody>
                  </table>

                  {isAdmin && selectedTimeEntry.is_approved === 1 && (
                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ margin: "5px", backgroundColor: "#0a8f45" }}
                        onClick={() => {
                          this.accept(selectedTimeEntry.id);
                          this.setState({ selectedTimeEntry: false });
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ margin: "5px", backgroundColor: "#db3535" }}
                        onClick={() => {
                          this.decline(selectedTimeEntry.id);
                          this.setState({ selectedTimeEntry: false });
                        }}
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                </div>
              </DialogContent>
            </div>
          </Dialog>
        )}
        {editDialogOpen && editEntry && (
          <Dialog open={editDialogOpen} onClose={this.handleEditDialogClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Edit Time Entry</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="User Name"
                type="text"
                fullWidth
                value={editEntry.name}
                disabled
              />
              <TextField
                fullWidth
                id="selectedDate"
                label="Date"
                name="date"
                type="date"
                error={formErrors.selectedDate && true}
                value={this.formatDateToInput(editEntry.date)}
                InputProps={{ inputProps: { max: ds } }}
                onChange={this.handleEditChange}
                className={formErrors.selectedDate && "input-error"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="dense"
                id="start-time"
                label="Start-time"
                type="time"
                name="in_time"
                fullWidth
                value={editEntry.in_time}
                onChange={this.handleEditChange}
                error={formErrors.startTime && true}
                helperText={formErrors.startTime}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
              />
              <TextField
                margin="dense"
                id="end-time"
                label="End Time"
                type="time"
                name="out_time"
                fullWidth
                value={editEntry.out_time}
                onChange={this.handleEditChange}
                error={formErrors.endTime && true}
                helperText={formErrors.endTime}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleEditDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleEditSubmit} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

export default Check;