import React, { Component } from "react";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Breadcrumb from "../Layouts/Breadcrumb";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import ls from "local-storage";
import { Dialog, DialogTitle, DialogContent, Typography, Button, CircularProgress } from "@material-ui/core";
import AuthApi from "../Services/Authapi";
import { Birthdatecard } from "./BirthdateCard";
import CloseIcon from "@material-ui/icons/Close";
import Tableforfilter from "./Filter/Tableforfilter";
import "./Dashboard.css";
import AuthCheckComponent from "../Layouts/Token";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import DragAndDropSections from "./DashboardDragDropContext";

let bdayID = false;

const styles = (theme) => ({
  congratsDialog: {
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#f0f8ff",
    position: "relative",
    textAlign: "center",
    width: "350px",
  },
  congratsTitle: {
    padding: "5px 0",
    backgroundColor: "#1B6E95",
    color: "#fff",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    fontSize: "1.5em",
  },
  congratsContent: {
    padding: "20px",
  },
  congratsText: {
    fontSize: "1.2em",
    color: "#333",
    marginBottom: "20px",
  },
  congratsCloseIcon: {
    position: "absolute",
    top: 19,
    right: 19,
    cursor: "pointer",
    color: "#fff",
    backgroundColor: "#f44336",
    borderRadius: "50%",
    padding: "2px",
  },
  congratsImage: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    marginBottom: "10px",
  },
  newJoined: {
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    marginBottom: "3%",
  },
  upcomingCard: {
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    marginBottom: "3%",
  },
  pendingLeavesCard: {
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    marginBottom: "3%",
  },
  holidayLeavesCard: {
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    marginBottom: "3%",
  },
  pendingLeavesHeader: {
    backgroundColor: "#1b6e95",
    color: "#fff",
    padding: "10px",
    textAlign: "center",
  },
  tableHeader: {
    backgroundColor: "#e0e0e0",
  },
  tableCell: {
    color: "#333",
  },
  pendingLeavesTable: {
    width: "100%",
  },
  pendingLeavesTableCell: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
  },
  pendingLeavesTableHeaderCell: {
    padding: "10px",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertIsOpen: false,
      page: 0,
      rowsPerPage: 5,
      rows: [],
      totalDurationTime: "",
      birthdayApiCalled: false,
      selectedDate: "",
      getData: [],
      userleave: [],
      current: 0,
      people: [],
      userID: false,
      show: true,
      activeStep: 0,
      activeStep1: 0,
      openEventModal: false,
      showFullDescription: false,
      eventDetails: null,
      congratsEmployee: null,
      pendingLeaves: [],
      dialogShown: localStorage.getItem("dialogShown") === "true",
      approvedLeaves: [],
      upcomingHolidays: [],
      timeEntries: { status: false, code: null, data: [] },
      usersWithCurrentMonthDOJ: [],
      congratsPopupShown: false,
      showLeaveDetailsPopup: false,
      selectedLeaveDetails: null,
      loading: false,
      sections: []
    };
  }
  componentWillMount() {
    if (this.props && this.props.authToken === false) {
      // alert("hardik")
      // this.props.history.push("/login");
    }
  }

  // componentWillReceiveProps(props) {
  //   if (props && props.authToken === false) {
  //     // Remove or comment out the redirection logic
  //     // this.props.history.push("/login");
  //   }
  // }
  componentWillReceiveProps(props) {
    const sessionItem = sessionStorage.getItem('mySessionItem');
    if (sessionItem === "someValue") {
      // this.props.history.push("/login");
    } else {
      this.props.history.push("/login");
    }
  }

  componentDidMount() {
    this.fetchSectionsOrderFromDatabase();
    this.getEventData();
    this.notificationForBday();
    this.handleClickOpen();
    this.getPendingLeaves();
    this.getUpcomingHolidays();
    this.getTimeEntries();
    this.userleave1();
    this.getUsersWithCurrentMonthDOJ();

    const { location } = this.props;
    if (
      location.state &&
      location.state.eventId &&
      sessionStorage.getItem("openEventModal") === "true"
    ) {
      this.fetchEventDetails(location.state.eventId);
      sessionStorage.removeItem("openEventModal");
    }

    const congratsPopupShown = localStorage.getItem("congratsPopupShown");
    if (!congratsPopupShown) {
      this.setState({ congratsPopupShown: false });
    } else {
      this.setState({ congratsPopupShown: true });
    }
  }

  fetchSectionsOrderFromDatabase = async () => {
    try {
      const userId = ls.get('user').id;
      const response = await AuthApi.getSectionsOrder(userId);
      const defaultSections = [
        { id: 'Upcomingevent' },
        { id: 'bfffday' },
        { id: 'pendingLeaves' },
        { id: 'upcomingHolidays' },
        { id: 'timeEntries' },
        { id: 'newJoinedEmployees' },
        { id: 'userleave' },
      ];

      if (response && response.status === true) {
        const fetchedSections = response.sections;
        this.setState({ sections: fetchedSections });

        const extraSections = defaultSections.filter(
          (section) => !fetchedSections.some((fetched) => fetched.id === section.id)
        );

        if (extraSections.length > 0) {
          const updatedSections = [...fetchedSections, ...extraSections];
          this.setState({ sections: updatedSections });
          this.saveSectionsOrderToDatabase(updatedSections);
        }
      } else {
        this.setState({ sections: defaultSections });
        this.saveSectionsOrderToDatabase(defaultSections);
      }
    } catch (error) {
      console.error("Error fetching sections order:", error);
    }
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sections = Array.from(this.state.sections);
    const [removed] = sections.splice(result.source.index, 1);
    sections.splice(result.destination.index, 0, removed);

    this.setState({ sections }, async () => {
      const userId = ls.get('user').id;
      const response = await AuthApi.getSectionsOrder(userId);

      if (response && response.status === true) {
        const fetchedSections = response.sections;
        const extraSections = sections.filter(
          (section) => !fetchedSections.some((fetched) => fetched.id === section.id)
        );

        if (extraSections.length > 0 || JSON.stringify(sections) !== JSON.stringify(fetchedSections)) {
          this.saveSectionsOrderToDatabase(sections);
        }
      } else {
        this.saveSectionsOrderToDatabase(sections);
      }
    });
  };

  saveSectionsOrderToDatabase = async (sections) => {
    try {
      await AuthApi.saveSectionsOrder({ sections });
    } catch (error) {
      console.error("Error saving sections order:", error);
    }
  };

  async getEventData() {
    const EventData = await AuthApi.getAllEvents();
    if (EventData && EventData.status === true) {
      this.setState({
        getData: EventData.data,
      });
    }
  }

  async fetchEventDetails(eventId) {
    const allEvents = await AuthApi.getAllEvents();
    if (allEvents && allEvents.status === true) {
      const eventDetails = allEvents.data.find((event) => event.id === eventId);
      if (eventDetails) {
        this.setState({
          eventDetails: eventDetails,
          openEventModal: true,
        });
      }
    }
  }

  notificationForBday = async () => {
    const bday = await AuthApi.bdayNotification();
    if (bday && bday.status === true) {
      this.setState({
        people: bday.data,
      });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
    });
  };

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  handleNext1 = () => {
    this.setState({
      activeStep1: this.state.activeStep1 + 1,
    });
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  handleBack1 = () => {
    this.setState({
      activeStep1: this.state.activeStep1 - 1,
    });
  };

  handleStepChange = (step) => {
    this.setState({
      activeStep: step,
    });
  };

  handleStepChange1 = (step) => {
    this.setState({
      activeStep1: step,
    });
  };

  handleClickOpen = () => {
    this.setState({
      alertIsOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      alertIsOpen: false,
      openEventModal: false,
      dialogShown: true,
    });
    localStorage.setItem("dialogShown", "true");
  };

  updateLeave = (ticketId, newStatus, reason) => {
    const user = ls("user");
    const userName = `${user.firstName} ${user.lastName}`;

    const ticketUpdateData = {
      leaveStatus: newStatus,
      c_reason: newStatus === "Cancelled" ? reason : "",
      cancel_reason: newStatus !== "Cancelled" ? reason : "",
      updated_by: userName,
    };

    this.setState({ loading: true });

    AuthApi.updateTicketLeaveStatus(ticketUpdateData, ticketId)
      .then((response) => {
        if (response && response.status === true) {
          swal("Success", "Ticket status updated successfully", "success");
        } else {
          swal("Error", "Failed to update ticket status", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating ticket status:", error);
        swal("Error", "Failed to update ticket status", "error");
      })
      .finally(() => {
        this.setState({ loading: false, showLeaveDetailsPopup: false });
        swal.close();
        this.getPendingLeaves();
      });

    const isApproved = newStatus === "Approved";
    const rejected = newStatus === "Declined";
    this.setState((prevState) => ({
      rejectedTickets: {
        ...prevState.rejectedTickets,
        [ticketId]: rejected ? reason : "",
      },
      approvedTickets: {
        ...prevState.approvedTickets,
        [ticketId]: isApproved,
      },
    }));
  };

  handleLeaveClick = (leave) => {
    this.setState({
      selectedLeaveDetails: leave,
      showLeaveDetailsPopup: true,
    });
  };

  handleCloseLeaveDetailsPopup = () => {
    this.setState({
      showLeaveDetailsPopup: false,
      selectedLeaveDetails: null,
    });
  };

  handleApproveLeave = (leaveId) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to approve this leave?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willApprove) => {
      if (willApprove) {
        swal({
          title: "Processing...",
          text: "Please wait while we process your request.",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          content: {
            element: "div",
            attributes: {
              innerHTML: '<div class="swal-loading"><i class="fa fa-spinner fa-spin"></i></div>',
            },
          },
        });
        this.updateLeave(leaveId, "Approved", "");
      }
    });
  };

  handleDeclineLeave = (leaveId) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to decline this leave?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDecline) => {
      if (willDecline) {
        swal({
          title: "Enter Reason",
          text: "Please enter the reason for declining the leave:",
          content: "input",
          buttons: {
            cancel: true,
            confirm: {
              text: "OK",
              closeModal: false,
            },
          },
        }).then((inputReason) => {
          if (!inputReason) {
            swal("Cancelled", "You need to write a reason!", "error");
            return;
          }
          swal({
            title: "Processing...",
            text: "Please wait while we process your request.",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            content: {
              element: "div",
              attributes: {
                innerHTML: '<div class="swal-loading"><i class="fa fa-spinner fa-spin"></i></div>',
              },
            },
          });
          this.updateLeave(leaveId, "Declined", inputReason);
        });
      }
    });
  };

  handleTimeEntryClick = (entry) => {
    sessionStorage.setItem('fromDashboard', 'true');
    this.props.history.push({
      pathname: "/check",
      state: { timeEntryDetails: entry },
    });
  };

  async getPendingLeaves() {
    const isAdmin = ls("roles") === "Super Admin";
    const isEmployeeRole = ls("roles") === "Employee";
    let pendingLeaves = [];
    let approvedLeaves = [];
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const filterCurrentMonthLeaves = (leaves) => {
      return leaves.filter((leave) => {
        const [startDate] = leave.date_range.split(" To ");
        const [day, month, year] = startDate.split("-").map(Number);
        return month === currentMonth && year === currentYear;
      });
    };

    if (isAdmin) {
      const leavesData = await AuthApi.getTickets1();
      if (leavesData && leavesData.status === true) {
        pendingLeaves = leavesData.data.filter(
          (leave) => leave.status === "Pending"
        );
      }
    } else if (isEmployeeRole) {
      const empLeavesData = await AuthApi.getEmpLeaveticket();
      if (empLeavesData && empLeavesData.status === true) {
        pendingLeaves = empLeavesData.data.filter(
          (leave) => leave.status === "Pending"
        );
        approvedLeaves = filterCurrentMonthLeaves(
          empLeavesData.data.filter((leave) => leave.status === "Approved")
        );
      }
    }
    this.setState({ pendingLeaves, approvedLeaves });
  }

  async getUpcomingHolidays() {
    const holidaysData = await AuthApi.getLeaves();
    if (holidaysData && holidaysData.status === true) {
      const upcomingHolidays = holidaysData.data
        .filter((holiday) => {
          const [day, month, year] = holiday.date.split("-").map(Number);
          const holidayDate = new Date(year, month - 1, day);
          return holidayDate >= new Date();
        }).sort((a, b) => {
          const [dayA, monthA, yearA] = a.date.split("-").map(Number);
          const [dayB, monthB, yearB] = b.date.split("-").map(Number);
          return (
            new Date(yearA, monthA - 1, dayA) -
            new Date(yearB, monthB - 1, dayB)
          );
        }).slice(0, 5);
      this.setState({ upcomingHolidays });
    }
  }



  async userleave1() {
    const userleave1 = await AuthApi.userleave();
    if (userleave1 && userleave1.status === true) {
      const userleave3 = userleave1.data;
      this.setState({ userleave: userleave3 });
    }
  }



  toggleDescription = () => {
    this.setState((prevState) => ({
      showFullDescription: !prevState.showFullDescription,
    }));
  };

  async getTimeEntries() {
    const timeEntriesData = await AuthApi.chekUserData();

    if (timeEntriesData) {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();

      const filteredEntries = timeEntriesData.data.filter((entry) => {
        const [day, month, year] = entry.date.split("-").map(Number);
        return month === currentMonth && year === currentYear;
      });

      this.setState(
        {
          timeEntries: { ...timeEntriesData, data: filteredEntries },
        }
      );
    }
  }

  async getUsersWithCurrentMonthDOJ() {
    const userData = await AuthApi.getData();
    if (userData && userData.status === true) {
      const currentMonth = new Date().getMonth() + 1;
      const currentDate = new Date().getDate();
      const usersWithCurrentMonthDOJ = userData.data.filter((user) => {
        const [day, month, year] = user.doj.split("-").map(Number);
        return month === currentMonth;
      });

      const congratsEmployee = usersWithCurrentMonthDOJ.find((user) => {
        const [year, month, day] = user.doj.split("-").map(Number);
        return day === currentDate;
      });

      if (congratsEmployee && !this.state.congratsPopupShown) {
        this.setState({ congratsEmployee });
      }

      this.setState({ usersWithCurrentMonthDOJ });
    }
  }

  handleCongratsClose = () => {
    this.setState({ congratsEmployee: null, congratsPopupShown: true });
    localStorage.setItem("congratsPopupShown", "true");
  };

  getStatusLabel = (isApproved) => {
    switch (isApproved) {
      case 0:
        return <span style={{ color: "green" }}>Approved</span>;
      case 1:
        return <span style={{ color: "orange" }}>Pending</span>;
      case 2:
        return <span style={{ color: "red" }}>Rejected</span>;
      default:
        return <span>Unknown</span>;
    }
  };



  render() {
    const { sections, pendingLeaves, approvedLeaves, upcomingHolidays, timeEntries, usersWithCurrentMonthDOJ, showLeaveDetailsPopup, selectedLeaveDetails, loading, dialogShown, people, getData, activeStep, activeStep1, show, openEventModal, eventDetails, congratsEmployee, showFullDescription, userleave } = this.state;
    const { classes, theme, authUser } = this.props;
    const pendingLeavesHeader = "Pending Leaves";
    const isSuperAdmin = ls("roles") === "Super Admin";


    const user = ls("user");
    const fs = user ? user.id : null;
    if (people.length && fs) {
      bdayID = people.some((data) => data.id === fs);
    }


    return (
      <div>
        <AuthCheckComponent authToken={this.props.authToken} />
        <Header
          {...this.props}
          authUser={authUser}
          setAutUser={this.props.setAutUser}
          component={
            <div>
              {!dialogShown && bdayID && (
                <Dialog
                  open={show}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <div className="Birthdatecard" style={{ overflow: "hidden" }}>
                    <Birthdatecard handleClose={this.handleClose} />
                  </div>
                </Dialog>
              )}
              <Card style={{ marginBottom: "3%" }}>
                <CardContent>
                  <Breadcrumb
                    {...this.props}
                    primaryPageName="Dashboard"
                    primaryPageLink="/dashboard"
                    isSecondaryPage={false}
                    secondaryPageName=""
                  />
                </CardContent>
              </Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card style={{ marginBottom: "3%", height: "100%" }}>
                    <CardContent>
                      <Tableforfilter props />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <DragAndDropSections
                sections={sections}
                onDragEnd={this.onDragEnd}
                classes={classes}
                theme={theme}
                permissions={authUser ? authUser.userAccess : {}}
                active={getData}
                activeStep={activeStep}
                handleStepChange={this.handleStepChange}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                maxStep={getData.length}
                active1={people}
                activeStep1={activeStep1}
                handleStepChange1={this.handleStepChange1}
                handleNext1={this.handleNext1}
                handleBack1={this.handleBack1}
                maxStep1={people.length}
                pendingLeaves={pendingLeaves}

                userleave={userleave}
                approvedLeaves={approvedLeaves}
                handleLeaveClick={this.handleLeaveClick}
                upcomingHolidays={upcomingHolidays}
                timeEntries={timeEntries}
                handleTimeEntryClick={this.handleTimeEntryClick}
                usersWithCurrentMonthDOJ={usersWithCurrentMonthDOJ}
                isSuperAdmin={isSuperAdmin}
                pendingLeavesHeader={pendingLeavesHeader}
              />

              {openEventModal && eventDetails && (
                <Dialog
                  open={openEventModal}
                  onClose={this.handleClose}
                  maxWidth="xs"
                  fullWidth
                  PaperProps={{
                    style: {
                      padding: "10px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                      width: "500px",
                    },
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <DialogTitle
                      style={{
                        textAlign: "center",
                        padding: "5px 0",
                        backgroundColor: "#1b6e95",
                        color: "#fff",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                      }}
                    >
                      Upcoming Event Details
                    </DialogTitle>
                    <CloseIcon
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        cursor: "pointer",
                        color: "#fff",
                        backgroundColor: "#f44336",
                        borderRadius: "50%",
                        padding: "2px",
                      }}
                      onClick={this.handleClose}
                    />
                    <DialogContent>
                      <div>
                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Name</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{eventDetails.name}</td>
                            </tr>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Date</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{eventDetails.date}</td>
                            </tr>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Description</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                                <div>
                                  {showFullDescription || eventDetails.description.length <= 50
                                    ? eventDetails.description
                                    : `${eventDetails.description.substring(0, 50)}...`}
                                  {eventDetails.description.length > 50 && (
                                    <div>
                                      <Button onClick={this.toggleDescription} color="primary">
                                        {showFullDescription ? "Read less" : "Read more"}
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Image</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                                <img src={eventDetails.image} alt={eventDetails.name} style={{ maxWidth: "200px", maxHeight: "111px" }} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </DialogContent>
                  </div>
                </Dialog>
              )}
              {congratsEmployee && (
                <Dialog
                  open={!!congratsEmployee}
                  onClose={this.handleCongratsClose}
                  aria-labelledby="congrats-dialog-title"
                  aria-describedby="congrats-dialog-description"
                  PaperProps={{
                    className: classes.congratsDialog,
                  }}
                >
                  <DialogTitle id="congrats-dialog-title" className={classes.congratsTitle}>
                    Congratulations!🎉
                    <CloseIcon
                      className={classes.congratsCloseIcon}
                      onClick={this.handleCongratsClose}
                    />
                  </DialogTitle>
                  <DialogContent className={classes.congratsContent}>
                    <img
                      src={congratsEmployee.photo}
                      alt={congratsEmployee.firstName}
                      className={classes.congratsImage}
                    />
                    <Typography className={classes.congratsText}>
                      Congratulations To {congratsEmployee.firstName} {congratsEmployee.lastName} On Joining Us Today As A {congratsEmployee.designation}!
                    </Typography>
                  </DialogContent>
                </Dialog>
              )}

              {showLeaveDetailsPopup && selectedLeaveDetails && (
                <Dialog
                  open={showLeaveDetailsPopup}
                  onClose={this.handleCloseLeaveDetailsPopup}
                  maxWidth="xs"
                  fullWidth
                  PaperProps={{
                    style: {
                      padding: "10px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                      width: "455px",
                      height: "400px"
                    },
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <DialogTitle
                      style={{
                        textAlign: "center",
                        padding: "5px 0",
                        backgroundColor: "#1b6e95",
                        color: "#fff",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                      }}
                    >
                      Leave Details
                    </DialogTitle>
                    <CloseIcon
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        cursor: "pointer",
                        color: "#fff",
                        backgroundColor: "#f44336",
                        borderRadius: "50%",
                        padding: "2px",
                      }}
                      onClick={this.handleCloseLeaveDetailsPopup}
                    />
                    <DialogContent>
                      <div>
                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Name</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.firstName}</td>
                            </tr>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Leave Type</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.leaveType}</td>
                            </tr>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Created On</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.created_at}</td>
                            </tr>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Reason for leave</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.ticket_mesasge}</td>
                            </tr>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Date Range</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.date_range}</td>
                            </tr>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>No Of Days</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.no_of_days}</td>
                            </tr>
                            <tr>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}><b>Status</b></td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>:</td>
                              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{selectedLeaveDetails.status}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {isSuperAdmin && selectedLeaveDetails.status === "Pending" && (
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.handleApproveLeave(selectedLeaveDetails.id); this.setState({ showLeaveDetailsPopup: false }); }}
                            style={{ marginRight: "10px", backgroundColor: "#0a8f45" }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { this.handleDeclineLeave(selectedLeaveDetails.id); this.setState({ showLeaveDetailsPopup: false }); }}
                            style={{ backgroundColor: "#db3535" }}
                          >
                            Declined
                          </Button>
                        </div>
                      )}
                      {loading && (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                          <CircularProgress />
                        </div>
                      )}
                    </DialogContent>
                  </div>
                </Dialog>
              )}
            </div>
          }
        />
        <Footer />
      </div>
    );
  }
}

export default withRouter(withTheme(withStyles(styles)(Dashboard)));