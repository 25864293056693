/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./AddForm.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AuthApi from "../../../Services/Authapi";
import swal from "sweetalert";
import ls from "local-storage";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FontAwesomeIconComponent from "../../../Layouts/FontAwesomeIconComponent";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const iniuservalid = {
  firstName: "",
  lastName: "",
  email: "",
  gender: "",
  dob: "",
  photo: null,
  imageName: "",
  qualification: "",
  last_organization: "",
  password: "",
  roleName: "",
  salary: "",
  mobileNo: "",
  bankName: "",
  bankAccNo: "",
  doj: "",
  location: "",
  department: "",
  designation: "",
  designation_id: "",
  reporting_person_id: [], // Change this to an array to hold multiple user IDs
  role: false,
};

const Addform = (props) => {
  const styles = useStyles();
  const classes = props;
  const [roleOptions] = React.useState([]);
  const [formValues, setFormValues] = useState(iniuservalid);
  const [formErrors, setFormErrors] = useState({});
  const [qualifications, setQualifications] = useState([]);
  const [Departmentshow, setDepartmentshow] = useState([]);
  const [Departmentshowd, setDepartmentshow1] = useState([]);
  const [reporting_person_id, setSelectuser] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]); // New state for filtered users
  const [selectedUserIds, setSelectedUserIds] = useState([]); // State to hold selected user IDs



  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // console.log(formValues);
  };

  const validate = (values) => {
    let errors = {};
    const regex = /^[a-zA-Z]/;
    const emailRegx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const dobRegx =
      /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;
    // const passRegx = /^[#\w@_-]{8,20}$/;
    // const passRegx = /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,16}$/;
    const Numeric = /^\d+$/;
    const phone = /^[0-9]{10}$/;

    if (!values.firstName) {
      errors.firstName = "Cannot be blank";
    } else if (!regex.test(values.firstName)) {
      errors.firstName = "Invalid firstName format";
    }

    if (!values.lastName) {
      errors.lastName = "Cannot be blank";
    } else if (!regex.test(values.lastName)) {
      errors.lastName = "Invalid lastName format";
    }

    if (!values.email) {
      errors.email = "Cannot be  blank";
    } else if (!emailRegx.test(values.email)) {
      errors.email = "Invalid email format";
    }

    if (!values.dob) {
      errors.dob = "Cannot be  blank";
    } else if (!dobRegx.test(values.dob)) {
      errors.dob = "Invalid dob format";
    }

    if (!values.qualification) {
      errors.qualification = "Cannot be blank";
    } else if (!regex.test(values.qualification)) {
      errors.qualification = "Invalid qualification format";
    }

    if (!values.password) {
      errors.password = "Password cannot be blank";
    } else if (!/^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/.test(values.password)) {
      errors.password = "Password must be 8 to 16 characters with at least one number, one capital letter, and one special character";
    }

    if (!values.roleName) {
      errors.roleName = "Cannot be blank";
    }

    if (!values.salary) {
      errors.salary = "Cannot be blank";
    } else if (!Numeric.test(values.salary)) {
      errors.salary = "Must Be Numeric";
    }

    if (!values.doj) {
      errors.doj = "Cannot be  blank";
    } else if (!dobRegx.test(values.doj)) {
      errors.doj = "Invalid dob format";
    }

    // if (!values.reporting_person_id) { 
    //   errors.reporting_person_id = "Cannot be  blank";
    // } 

    return errors;
  };


  useEffect(() => {
    getQualificationData();
    Departmentshow1()
    Departmentshow2()
    Selectuser1()
  }, []);

  const getQualificationData = async () => {
    try {
      const response = await AuthApi.getQulification();
      if (response && response.data) {
        setQualifications(response.data);
      }
    } catch (error) {
      console.error('Error fetching qualification data:', error);
    }
  };
  const Selectuser1 = async () => {
    try {
      let users = await AuthApi.getData();
      if (users && users.data) {
        setSelectuser(users.data);
      }
    } catch (error) {
      console.error('Error fetching qualification data:', error);
    }
  };


  const Departmentshow1 = async () => {
    try {
      const response = await AuthApi.Departmentshow();
      if (response && response.data) {
        setDepartmentshow(response.data);
      }
    } catch (error) {
      console.error('Error fetching qualification data:', error);
    }
  };


  const Departmentshow2 = async () => {
    try {
      const response = await AuthApi.Departmentshow1();
      if (response && response.data) {
        setDepartmentshow1(response.data);
      }
    } catch (error) {
      console.error('Error fetching qualification data:', error);
    }
  };







  async function RoleData() {
    let role = await AuthApi.getRole();
    if (role && role.status === true) {
      if (Object.keys(role.data).length > 0) {
        Object.keys(role.data).forEach((key) => {
          roleOptions.push(
            <MenuItem key={key} value={role.data[key].name}>
              {role.data[key].name}
            </MenuItem>
          );
        });
      } else {
        roleOptions.push(
          <MenuItem value="">
            <em>Select Role</em>
          </MenuItem>
        );
      }
    }
  }
  React.useEffect(() => { RoleData() }, []);

  const imgUpload = async (e) => {
    const postData = new FormData();
    postData.append("file", e.target.files[0]);
    let updaImg = await AuthApi.updateImg(postData);
    if (updaImg && updaImg.status === true) {
      setFormValues({
        ...formValues,
        photo: updaImg.data.image_url,
        imageName: updaImg.data.image_name,
      });
    }
  };

  const removeImg = async (e) => {
    let imageLink = formValues.photo;
    imageLink = imageLink.substr(imageLink.indexOf("/", 7) + 1);
    let remImg = await AuthApi.deleteImg(imageLink);
    if (remImg && remImg.status === true) {
      setFormValues({
        ...formValues,
        photo: null,
        imageName: null,
      });
    } else {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      createUser();
    }
  }, [formErrors]);

  const createUser = async () => {
    swal({
      title: "Loading",
      text: "Please wait...",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      width: "10%",
      content: {
        element: "img",
        attributes: {
          src: "https://www.boasnotas.com/img/loading2.gif",
          style: "width: 50px;"
        }
      }
    });
    try {

      let create = await AuthApi.createUser(formValues);
      swal.close();
      // console.log(create);

      if (create && create.status === true) {
        props.history.push("/users");
      } else {
        swal({
          title: "OOPS!",
          icon: "fail",
          message: "Something went wrong, Please try later!",
        });
      }
    } catch (error) {
      console.error("Error updating users:", error);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container className={classes.root} spacing={5}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              variant="outlined"
              error={formErrors.firstName && true}
              value={formValues.firstName}
              onChange={handleChange1}
              className={formErrors.firstName && "input-error"}
            />
            {formErrors.firstName && (
              <span className="error">{formErrors.firstName}</span>
            )}
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              variant="outlined"
              error={formErrors.lastName && true}
              value={formValues.lastName}
              onChange={handleChange1}
              className={formErrors.lastName && "input-error"}
            />
            {formErrors.lastName && (
              <span className="error">{formErrors.lastName}</span>
            )}
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              error={formErrors.email && true}
              value={formValues.email}
              onChange={handleChange1}
              className={formErrors.email && "input-error"}
            />
            {formErrors.email && (
              <span className="error">{formErrors.email}</span>
            )}
          </Grid>
        </Grid>

        <Grid container className={classes.root} spacing={5}>
          <Grid item xs={4} style={{ display: "flex" }}>
            <FormControl component="fieldset">
              <Grid container className={classes.root} spacing={4}>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <FormLabel component="legend">Gender</FormLabel>
                </Grid>
              </Grid>
              <RadioGroup
                aria-label="gender"
                name="gender"
                // defaultValue="female"
                onChange={handleChange1}
              >
                <Grid container className={classes.root} spacing={2}>
                  <Grid textalign="left" item xs={6}>
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="date"
              variant="outlined"
              label="Birthday"
              name="dob"
              type="date"
              error={formErrors.dob && true}
              value={formValues.dob}
              onChange={handleChange1}
              className={formErrors.dob && "input-error"}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {formErrors.dob && <span className="error">{formErrors.dob}</span>}
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="date"
              variant="outlined"
              label="Date of Join"
              name="doj"
              type="date"
              error={formErrors.doj && true}
              value={formValues.doj}
              onChange={handleChange1}
              className={formErrors.doj && "input-error"}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {formErrors.doj && (
              <span className="error">{formErrors.doj}</span>
            )}
          </Grid>
        </Grid>

        <Grid container className={classes.root} spacing={5}>
          <Grid item xs={4}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-outlined-label">Qualification </InputLabel>
              <Select
                fullWidth
                id="outlined-basic"
                name="qualification"
                label="Qualification"
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                // name="minqualification"
                error={formErrors.qualification && true}
                value={formValues.minqualification}
                onChange={handleChange1}
                className={formErrors.minqualification && "input-error"}
              ><MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {qualifications.map((qualification) => (
                  <MenuItem key={qualification.id} value={qualification.qualification}>
                    {qualification.qualification}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formErrors.qualification && (
              <span className="error">{formErrors.qualification}</span>
            )}
          </Grid>
 

          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              name="salary"
              label="Salary"
              variant="outlined"
              value={
                formValues && formValues.salary !== null
                  ? formValues.salary
                  : null
              }
              onChange={handleChange1}
              className={formErrors.salary && "input-error"}
              error={formErrors.salary && true}
            />
            {formErrors.salary && (
              <span className="error">{formErrors.salary}</span>
            )}
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              name="password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              variant="outlined"
              error={formErrors.password && true}
              value={formValues.password}
              onChange={handleChange1}
              className={formErrors.password && "input-error"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formErrors.password && (
              <span className="error">{formErrors.password}</span>
            )}
          </Grid>
        </Grid>

        <Grid container className={classes.root} spacing={5}>
          <Grid item xs={4}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Role
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="roleName"
                label="Role"
                error={formErrors.roleName && true}
                value={formValues.roleName}
                onChange={handleChange1}
                className={formErrors.roleName && "input-error"}
              >
                {roleOptions}
              </Select>
            </FormControl>
            {formErrors.roleName && (
              <span className="error">{formErrors.roleName}</span>
            )}
          </Grid>
        </Grid>



        <Grid container className={classes.root} spacing={5}>
          <Grid item xs={12}>
            <div className={styles.root}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ color: "#1B6E95" }}
                >
                  <Typography className={styles.heading}>
                    Other Settings
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container className={classes.root} spacing={5}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        name="location"
                        label="Location"
                        variant="outlined"
                        value={
                          formValues && formValues.location !== null
                            ? formValues.location
                            : null
                        }
                        onChange={handleChange1}
                        className={formErrors.location && "input-error"}
                        error={formErrors.location && true}
                      />
                      {formErrors.location && (
                        <span className="error">{formErrors.location}</span>
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        name="mobileNo"
                        label="Mobile NO"
                        variant="outlined"
                        value={
                          formValues && formValues.mobileNo !== null
                            ? formValues.mobileNo
                            : null
                        }
                        onChange={handleChange1}
                        className={formErrors.mobileNo && "input-error"}
                        error={formErrors.mobileNo && true}
                      />
                      {formErrors.mobileNo && (
                        <span className="error">{formErrors.mobileNo}</span>
                      )}
                    </Grid>

                    <Grid item xs={4} style={{ display: "flex" }}>
                      {formValues.photo === null ? (
                        <div>
                          <input
                            accept="image/*"
                            className={classes.input}
                            name="photo"
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => imgUpload(e)}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span">
                              {" "}
                              Upload Profile Picture{" "}
                            </Button>
                          </label>
                        </div>
                      ) : (
                        <div>
                          <Card className={classes.root}>
                            <CardContent>
                              <Grid container className={classes.root} spacing={5}>
                                <Grid item xs={4}>
                                  <img
                                    src={formValues.photo}
                                    alt="User profile image"
                                    width="35"
                                    height="35"
                                  />
                                </Grid>
                                <input
                                  className={classes.input}
                                  name="photo"
                                  id="photo"
                                  type="hidden"
                                  value={formValues.photo ? formValues.photo : ""}
                                />
                                <Grid item xs={4}>
                                  <Typography variant="subtitle1" gutterBottom>
                                    {formValues.imageName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button onClick={(e) => removeImg(e)}>
                                    <FontAwesomeIconComponent
                                      classes="fa fa-trash"
                                      colorName="primary"
                                      fontSize={"small"}
                                    />
                                  </Button>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
                <AccordionDetails>
                  <Grid container className={classes.root} spacing={5}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        name="bankAccNo"
                        label="Bank Account Number"
                        variant="outlined"
                        value={
                          formValues && formValues.bankAccNo !== null
                            ? formValues.bankAccNo
                            : null
                        }
                        onChange={handleChange1}
                        className={formErrors.bankAccNo && "input-error"}
                        error={formErrors.bankAccNo && true}
                      />
                      {formErrors.bankAccNo && (
                        <span className="error">{formErrors.bankAccNo}</span>
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        name="bankName"
                        label="Bank Name"
                        variant="outlined"
                        value={
                          formValues && formValues.bankName !== null
                            ? formValues.bankName
                            : null
                        }
                        onChange={handleChange1}
                        className={formErrors.bankName && "input-error"}
                        error={formErrors.bankName && true}
                      />
                      {formErrors.bankName && (
                        <span className="error">{formErrors.bankName}</span>
                      )}
                    </Grid>


                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">Department </InputLabel>
                        <Select
                          fullWidth
                          id="outlined-basic"
                          name="department"
                          label="Department"
                          variant="outlined"
                          labelId="demo-simple-select-outlined-label"
                          error={formErrors.department && true}
                          value={formValues.department}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setFormValues({
                              ...formValues,
                              department: selectedValue,
                            });
                          }}
                          className={formErrors.department && "input-error"}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {Departmentshowd.map((qualification) => (
                            <MenuItem key={qualification.id} value={qualification.department}>
                              {qualification.department}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formErrors.department && (
                        <span className="error">{formErrors.department}</span>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>

                <AccordionDetails>
                  <Grid container className={classes.root} spacing={5}>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">Designation </InputLabel>
                        <Select
                          fullWidth
                          id="outlined-basic"
                          name="designation"
                          label="Designation"
                          variant="outlined"
                          labelId="demo-simple-select-outlined-label"
                          error={formErrors.Designation && true}
                          value={formValues.designation}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            const selectedId = Departmentshow.find(dept => dept.designation === selectedValue)?.id;
                            setFormValues({
                              ...formValues,
                              designation: selectedValue,
                              designation_id: selectedId
                            });
                            const filtered = reporting_person_id.filter(user => user.designation === selectedValue);
                            setFilteredUsers(filtered); 
                          }}
                          className={formErrors.designation && "input-error"}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {Departmentshow.map((qualification) => (
                            <MenuItem key={qualification.id} value={qualification.designation}>
                              {qualification.designation}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formErrors.designation && (
                        <span className="error">{formErrors.designation}</span>
                      )}
                    </Grid>



                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">Selectuser </InputLabel>
                        <Select
                          fullWidth
                          id="outlined-basic"
                          name="assigned_users"
                          label="Selectuser"
                          variant="outlined"
                          labelId="demo-simple-select-outlined-label"
                          error={formErrors.reporting_person_id && true}
                          multiple
                          value={formValues.reporting_person_id} 
                          onChange={(e) => {
                            const { value } = e.target;
                            setFormValues({
                              ...formValues,
                              reporting_person_id: value,
                            });
                          }}
                          className={formErrors.reporting_person_id && "input-error"}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {filteredUsers.map((user) => ( 
                            <MenuItem key={user.id} value={user.id}> 
                              {user.firstName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formErrors.reporting_person_id && (
                        <span className="error">{formErrors.reporting_person_id}</span>
                      )}
                    </Grid>


                    {/* {ls("roles") === "Admin" ||
                    ls("roles") === "Super Admin" ? ( */}
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        name="last_organization"
                        label="Last Organization"
                        variant="outlined"
                        error={formErrors.last_organization && true}
                        value={formValues.last_organization}
                        onChange={handleChange1}
                        className={formErrors.last_organization && "input-error"}
                      />
                      {formErrors.last_organization && (
                        <span className="error">{formErrors.last_organization}</span>
                      )}
                    </Grid>
                    {/* ) : (
                      ""
                    )} */}

                    <Grid item xs={4}></Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>

        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={4} style={{ display: "flex" }}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                props.history.push("/users");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Addform;
