import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AuthApi from '../../Services/Authapi';
import swal from 'sweetalert';
import AuthCheckComponent from '../../Layouts/Token';
import moment from "moment";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ls from "local-storage";
import { MdAddBox } from 'react-icons/md';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const inivalue = {
    selectedDate: "",
    startTime: "",
    endTime: ""
}

const iniDoc = {
    id: "",
};

const AddTime = (props) => {
    const classes = props;
    const [addValues, setAddValues] = useState(inivalue);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [doc, setDoc] = useState(iniDoc);
    const [notificationDetails, setNotificationDetails] = useState([]);
    const [userRole, setUserRole] = useState(ls("roles"));
    const [dataFetched, setDataFetched] = useState(false);
    const [hoveredButton, setHoveredButton] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredButton(index);
    };

    const handleMouseLeave = () => {
        setHoveredButton(null);
    };

    const handleClickOpen = (detail) => {
        setSelectedDetail(detail);
        setAddValues({
            ...addValues,
            startTime: convertTo24HourFormat(detail.in_time),
            endTime: convertTo24HourFormat(detail.out_time)
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }
        userData();
    }, []);

    const userData = async () => {
        try {
            let users = await AuthApi.getData();
            if (users && users.status === true) {
                const loggedInUsers = users.data.filter(user => user.user_logged === 1);
                setFilteredUsers(loggedInUsers);
                // Assuming the API returns the role of the logged-in user
                const currentUser = users.data.find(user => user.id === props.authUser.id);
                setUserRole(currentUser.role);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const handleChange1 = async (e) => {
        const { name, value } = e.target;
        setDoc({ ...doc, [name]: value });

        if (name === "firstName") {
            const selectedUser = filteredUsers.find(user => user.firstName === value);
            if (selectedUser) {
                setDoc(prevDoc => ({
                    ...prevDoc,
                    id: selectedUser.id,
                }));
                if (addValues.selectedDate) {
                    await fetchNotifications(selectedUser.id, addValues.selectedDate);
                }
            }
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setAddValues({ ...addValues, [name]: value });
        if (name === "selectedDate" && (doc.id || userRole === 'Employee')) {
            await fetchNotifications(doc.id || props.authUser.id, value);
        }
    };

    const fetchNotifications = async (userId, selectedDate) => {
        const date = moment(selectedDate, 'YYYY-MM-DD');
        const year = date.year();
        const month = date.month() + 1;
        const day = date.date();

        let data;
        if (userRole === 'Super Admin') {
            data = await AuthApi.ShowMissingTimeEntriesById(userId, day, month, year);
        } else if (userRole === 'Employee') {
            data = await AuthApi.ShowMissingTimeEntriesById(ls("user").id, day, month, year);
        }

        if (data && Array.isArray(data.data)) {
            setNotificationDetails(data.data);
        } else {
            console.error("Error: data.data is not iterable or no data returned");
            setNotificationDetails([]);
        }
        setDataFetched(true);
    };

    const validate = (values) => {
        let errors = {};
        const dobRegx = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;

        if (!values.selectedDate) {
            errors.selectedDate = "Cannot be blank";
        } else if (!dobRegx.test(values.selectedDate)) {
            errors.selectedDate = "Invalid dob format";
        }

        if (!values.startTime) {
            errors.startTime = "Cannot be blank";
        }

        if (!values.endTime) {
            errors.endTime = "Cannot be blank";
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(addValues));
        setIsSubmitting(true);
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            addTimeData();
        }
    }, [formErrors]);

    const addTimeData = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;"
                }
            }
        });
        try {
            const payload = {
                ...addValues,
                id: userRole === 'Super Admin' ? doc.id : ls("user").id
            };
            let create1 = await AuthApi.addTime(payload);
            swal.close();
            if (create1 && create1.status === true) {
                props.history.push('/check');
            } else {
                swal("Error", create1, "error");
            }
        } catch (error) {
            swal.close();
            swal({
                title: "OOPS!",
                icon: "error",
                text: "Something went wrong, Please try later!"
            });
            console.error("Error updating time:", error);
        }
    };

    const convertTo24HourFormat = (time) => {
        const [timePart, modifier] = time.split(' ');
        let [hours, minutes] = timePart.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    };

    const handleAddTime = () => {
        handleSubmit({ preventDefault: () => { } }); 
    };

    const handlePopupChange = (e) => {
        const { name, value } = e.target;
        setAddValues({ ...addValues, [name]: value });
    };

    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + (d.getDate() ),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    var ds = [year, month, day].join('-');

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="User Check"
                                    primaryPageLink="/check"
                                    isSecondaryPage={true}
                                    secondaryPageName="Add Time Entry" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2">Add Time-Entry</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit}>
                                            <Grid container className={classes.root} spacing={5}>
                                                {userRole === 'Super Admin' && (
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">User First Name</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                name="firstName"
                                                                value={doc.firstName}
                                                                label="User First Name"
                                                                error={formErrors.firstName && true}
                                                                onChange={handleChange1}
                                                                className={formErrors.firstName && "input-error"}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {filteredUsers.map((item) => (
                                                                    <MenuItem key={item.id} value={item.firstName}>
                                                                        {item.firstName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {formErrors.firstName && (
                                                                <span className="error">{formErrors.firstName}</span>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                )}
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        id="selectedDate"
                                                        variant="outlined"
                                                        label="Date"
                                                        name="selectedDate"
                                                        type="date"
                                                        error={formErrors.selectedDate && true}
                                                        value={addValues.selectedDate}
                                                        defaultValue="2017-05-24"
                                                        InputProps={{ inputProps: { max: ds } }}
                                                        onChange={handleChange}
                                                        className={formErrors.selectedDate && "input-error"}
                                                        // className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    {formErrors.selectedDate && (
                                                        <span className="error">{formErrors.selectedDate}</span>
                                                    )}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        id="time"
                                                        label="Start-time"
                                                        variant="outlined"
                                                        type="time"
                                                        name="startTime"
                                                        error={formErrors.startTime && true}
                                                        value={addValues.startTime}
                                                        onChange={handleChange}
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300,
                                                        }}
                                                    />
                                                    {formErrors.startTime && (
                                                        <span className="error">{formErrors.startTime}</span>
                                                    )}
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        id="time"
                                                        label="End-time"
                                                        variant="outlined"
                                                        type="time"
                                                        name="endTime"
                                                        error={formErrors.endTime && true}
                                                        value={addValues.endTime}
                                                        onChange={handleChange}
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300,
                                                        }}
                                                    />
                                                    {formErrors.endTime && (
                                                        <span className="error">{formErrors.endTime}</span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4} style={{ display: 'flex' }}>
                                                    <Button type="submit" style={{ marginTop: "10px" }} variant="contained" color="primary">Save</Button>
                                                    <Button type="button" style={{ marginTop: "10px" }} onClick={(e) => { e.preventDefault(); props.history.push('/check') }}>Cancel</Button>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}></Grid>
                                            </Grid>
                                        </form>

                                        {dataFetched && (
                                            notificationDetails.length > 0 ? (
                                                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                                                    <Table>
                                                        <TableHead style={{ backgroundColor: "#1b6e95", color: "white" }}>
                                                            <TableRow>
                                                                <TableCell style={{ color: "white" }}>Employee ID</TableCell>
                                                                <TableCell style={{ color: "white" }}>Name</TableCell>
                                                                <TableCell style={{ color: "white" }}>Date</TableCell>
                                                                <TableCell style={{ color: "white" }}>Day</TableCell>
                                                                <TableCell style={{ color: "white" }}>In Time</TableCell>
                                                                <TableCell style={{ color: "white" }}>Out Time</TableCell>
                                                                <TableCell style={{ color: "white" }}>Duration</TableCell>
                                                                <TableCell style={{ color: "white" }}>Action</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {notificationDetails.map((detail, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{detail.emp_id}</TableCell>
                                                                    <TableCell>{detail.name}</TableCell>
                                                                    <TableCell>{detail.date}</TableCell>
                                                                    <TableCell>{moment(detail.date, 'DD-MM-YYYY').format('dddd')}</TableCell>
                                                                    <TableCell>{detail.in_time}</TableCell>
                                                                    <TableCell>{detail.out_time}</TableCell>
                                                                    <TableCell>{detail.duration}</TableCell>
                                                                    <Tooltip title="Add Time">
                                                                        <TableCell>
                                                                            <MdAddBox
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    color: hoveredButton === index ? "#000" : "#1b6e95",
                                                                                    transition: 'color 0.3s'
                                                                                }}
                                                                                onClick={() => handleClickOpen(detail)}
                                                                                size={26}
                                                                                onMouseEnter={() => handleMouseEnter(index)}
                                                                                onMouseLeave={handleMouseLeave}
                                                                            />
                                                                        </TableCell>
                                                                    </Tooltip>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            ) : (
                                                <TableRow style={{ display: "inline" }}>
                                                    <TableCell colSpan={8} align="center">
                                                        No missing entries for this day
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </div>
                } />
            <Footer />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Add Time Entry</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        Please fill in the details below to add a time entry.
                    </DialogContentText> */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="User Name"
                        type="text"
                        fullWidth
                        value={selectedDetail ? selectedDetail.name : ''}
                        disabled
                    />
                    <TextField
                        fullWidth
                        id="selectedDate"
                        label="Date"
                        name="selectedDate"
                        type="date"
                        error={formErrors.selectedDate && true}
                        value={addValues.selectedDate}
                        defaultValue="2017-05-24"
                        InputProps={{ inputProps: { max: ds } }}
                        onChange={handleChange}
                        className={formErrors.selectedDate && "input-error"}
                        // className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="start-time"
                        label="Start-time"
                        type="time"
                        name="startTime"
                        fullWidth
                        value={addValues.startTime}
                        onChange={handlePopupChange}
                        error={formErrors.startTime && true}
                        helperText={formErrors.startTime}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300,
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="end-time"
                        label="End Time"
                        type="time"
                        name="endTime"
                        fullWidth
                        value={addValues.endTime}
                        onChange={handlePopupChange}
                        error={formErrors.endTime && true}
                        helperText={formErrors.endTime}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddTime} color="primary">
                        Add Time
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddTime;